////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
import React, { useState, useEffect, useContext, useRef, Fragment, useMemo } from 'react';

// SASS & CSS 연결
import "./preset/ChatV1Preset.scss"
// [프로바이더]
import EditContext from 'service/context/EditContext';

// 서비스 연결
import * as Widget from 'service/edit/WidgetService';
import * as Preset from './preset/ChatV1Preset';

////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////

function ParticlesV2({
  keyValue,
  pageKey,
  alias,
  type,
  key,
  id,
  uuid,
  getStyle,
  getAnimation,
  animation,
  parents,
  data
}) {
  ////////////////////////////////////////////////////////
  // Const
  ////////////////////////////////////////////////////////

  const WidgetRef = useRef(null);
  const CacheRef = useRef(null); // currentTime을 저장할 ref 생성
  const context = useContext(EditContext);

  /* 모든 위젯에서 사용되는 고유 상수 */
  const Style = getStyle(data); // 스타일 정보
  const Animation = getAnimation(animation, data); // 애니메이션 정보
  const MergeStyle = Widget.styleMerge(Style, Animation); // 스타일과 애니메이션을 합침

  const WidgetActivate = Widget.activate(data); // 위젯 숨김 여부 판단
  const Width = data?.size?.width; // 위젯의 가로 사이즈
  const Height = data?.size?.height; // 위젯의 세로 사이즈

  ////////////////////////////////////////////////////////
  // State 및 추가 설정
  ////////////////////////////////////////////////////////
  // const images = [
  //   'http://localhost:3000/static/media/logoW.6ff7bbab.svg'
  // ]; // 사용할 이미지 URL 배열

  const images = useMemo(() => [
    'http://localhost:3000/static/media/logoW.6ff7bbab.svg'
  ], []);

  
  const speed = 2;           // 낙하 속도 (픽셀 단위)
  const shakeAmplitude = 2;  // 흔들림 세기 (픽셀 단위)
  const canvasWidth = Width; // 캔버스 너비
  const canvasHeight = Height; // 캔버스 높이

  // 이미지 사이즈 조절 (data.imageSize가 없으면 기본값 50×50)
  const imageWidth = data?.imageSize?.width || 50;
  const imageHeight = data?.imageSize?.height || 50;

  // 낙하할 이미지(드롭)의 갯수 조절 (data.dropCount가 없으면 기본값 10)
  const dropCount = data?.dropCount || 1;

  ////////////////////////////////////////////////////////
  // Func (캔버스 애니메이션)
  ////////////////////////////////////////////////////////

  const canvasRef = useRef(null);
  const animationFrameRef = useRef(null);
  const dropsRef = useRef([]); // 캔버스에 그릴 낙하 이미지들의 상태 저장


  useEffect(() => {



  }, []);

  
  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    // 1. 이미지들을 미리 로드
    const loadedImages = images.map(src => {
      const img = new Image();
      img.src = src;
      return img;
    });

    // 2. 모든 이미지가 로드되면, dropCount에 맞춰 캔버스 상단에 배치합니다.
    const initializeDrops = () => {
      dropsRef.current = Array.from({ length: dropCount }).map(() => ({
        // 여러 이미지가 있을 경우 무작위로 선택
        img: loadedImages[Math.floor(Math.random() * loadedImages.length)],
        // baseX: 기본 x 좌표 (여기서 흔들림 효과가 적용됩니다.)
        baseX: Math.random() * canvasWidth,
        // y: 캔버스 위(보이지 않는 영역)에서 시작
        y: -Math.random() * canvasHeight,
        // angle: 흔들림 각도 (초기값)
        angle: Math.random() * Math.PI * 2,
        // angleSpeed: 매 프레임마다 증가할 각도 (드롭마다 약간 다르게 설정)
        angleSpeed: 0.05 + Math.random() * 0.05,
      }));
    };

    // 이미지 로드 여부 체크
    const checkImagesLoaded = () => {
      if (loadedImages.every(img => img.complete)) {
        initializeDrops();
        startAnimation();
      } else {
        setTimeout(checkImagesLoaded, 100);
      }
    };

    // 3. 애니메이션 루프: 각 드롭의 위치 업데이트 및 캔버스 재그리기
    const startAnimation = () => {
      const animate = () => {
        // 캔버스 전체 클리어
        ctx.clearRect(0, 0, Width, Height);

        dropsRef.current.forEach(drop => {
          // y 좌표 업데이트 (낙하 속도 적용)
          drop.y += speed;
          // 각도 업데이트 (사인 함수를 위한 값)
          drop.angle += drop.angleSpeed;
          // 현재 x 좌표 계산: 기본 좌표 + 사인값을 곱한 흔들림 효과
          const x = drop.baseX + Math.sin(drop.angle) * shakeAmplitude;

          // 이미지를 지정한 사이즈로 그립니다.
          ctx.drawImage(drop.img, x, drop.y, imageWidth, imageHeight);

          // 이미지가 캔버스 아래로 벗어나면 다시 위쪽에서 등장
          if (drop.y > canvasHeight) {
            drop.y = -imageHeight;
            drop.baseX = Math.random() * canvasWidth;
            drop.angle = Math.random() * Math.PI * 2;
          }
        });

        // 다음 애니메이션 프레임 요청
        animationFrameRef.current = requestAnimationFrame(animate);
      };

      animate();
    };

    checkImagesLoaded();

    // 컴포넌트 언마운트 시 애니메이션 프레임 취소 (메모리 클린업)
    return () => {
      cancelAnimationFrame(animationFrameRef.current);
    };
  }, [
    images,
    speed,
    shakeAmplitude,
    // canvasWidth,
    // canvasHeight,
    imageWidth,
    imageHeight,
    dropCount
  ]);

  ////////////////////////////////////////////////////////
  // Widget 렌더링
  ////////////////////////////////////////////////////////

  switch (parents) {
    case 'edit':
      // 편집화면에서는 애니메이션 효과 비활성화
      MergeStyle.layout.transition = false;
      MergeStyle.object.transition = false;
      return (
        <Fragment>
          <style jsx>{`${WidgetStyle}`}</style>
          <div
            style={{ ...MergeStyle.layout }}
            className={`${parents} widgetObject ${type}${WidgetActivate}`}
          >
            <div style={{ ...MergeStyle.object }} >
            <canvas ref={canvasRef} width={canvasWidth} height={canvasHeight} />
            </div>
``
          </div>
        </Fragment>
      );
    case 'broadcast':
      return (
        <Fragment>
          <style jsx>{`${WidgetStyle}`}</style>
          <div
            style={{ ...MergeStyle.layout }}
            className={`${parents} widgetObject ${type}${WidgetActivate}`}
          >
            <canvas ref={canvasRef} width={canvasWidth} height={canvasHeight}/>
          </div>
        </Fragment>
      );
    case 'view':
      return (
        <Fragment>
          <style jsx>{`${WidgetStyle}`}</style>
          <div
            style={{ ...MergeStyle.layout }}
            className={`${parents} widgetObject ${type}${WidgetActivate}`}
          >
            <canvas ref={canvasRef} width={canvasWidth} height={canvasHeight} />
          </div>
        </Fragment>
      );
    default:
      return (
        <Fragment>
          <style jsx>{`${WidgetStyle}`}</style>
          <div
            style={{ ...MergeStyle.layout }}
            className={`${parents} widgetObject ${type}${WidgetActivate}`}
          >
            <canvas
              ref={canvasRef}
              width={canvasWidth}
              height={canvasHeight}
              style={{ border: '1px solid #000' }}
            />
          </div>
        </Fragment>
      );
  }
}

export default ParticlesV2;

const WidgetStyle = `
  /* 추가 스타일을 여기에 정의할 수 있습니다. *
  
  .Particles .widgetPlaceholder{
   background-color: rgba(#222, 0.2)
  
  
  }
`;
