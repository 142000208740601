////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component , Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/widgetEdit.scss"


// 컴포넌트 연결
// import Show from '../show/Show'; // 메뉴 헤더

// 서비스 연결

////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
class TextModule extends Component {

    force(click){
        if (click){
            if(this.module){
                this.module.focus()
            }
            this.props.templateUnClick()
        }

    }

    componentDidMount () {
        this.module.focus()
    }

    componentWillUnmount () {
        console.log('언마운트')
        if(this.module){
            this.module.blur()
        }
    }

    handleKeyUp = (e) => {
        console.log('toLowerCase',e.ctrlKey)
        if (e.ctrlKey) {
            this.module.blur()
        }
        switch (e.key.toLowerCase()) {
         
          case 'delete':
            this.module.blur()       
            break;
        }

      };

    render() { 

        return (
            <label className="TextModule" htmlfor="fname"> 
                <p>{this.props.title?this.props.title:'본문'}</p>
                <textarea ref={(ref) => this.module=ref } onKeyUp={this.handleKeyUp}  placeholder={this.props.placeholder} style={this.props.fontFamily?{fontFamily:this.props.fontFamily}:{fontFamily:'나눔스퀘어'}} value={this.props.value} onChange={(e) => this.props.change(this.props.type,e.target.value)}/>
            </label>
        );
    }
}

////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////


////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    templateCilck : state.templateCilck,
})

function mapDispatchToProps(dispatch){
    return {
        templateUnClick : () => dispatch({type:'templateClick',click : false}),
    //   exempleDispatch : (exemple) => dispatch({type:'exempleName',exempleProps : exemple}),
    }
}

  // export default Login;
  export default connect(mapStateToProps,mapDispatchToProps)(TextModule);
  