// -- 위젯의 정의파일 Model 입니다. 해당 파일을 widgetModel 파일에 import 시켜야 사용 가능 합니다. -- 

////////////////////////////////////////////////////////
// 'widgetName' | '한글 위젯 이름' 
// '위젯 설명'
////////////////////////////////////////////////////////

// -- 컴포넌트 불러오기 -- ('components/widget/[위젯이름]') 위치로 가저올 수 있습니다.
import Timer from 'components/widget/TimerV2'; // 컴포넌트
// import Timer from 'components/widget/_Test0210'; // 컴포넌트

// -- 스타일 불러오기 -- ('service/model/widget/[스타일이름]') 위치로 가저올 수 있습니다.
import * as common from 'service/model/widget/commonStyle'; // 자주 사용되는 스타일 메이커 가저오기
import * as common_v2 from 'service/model/widget/commonStyle_V2'; // 자주 사용되는 스타일 메이커 가저오기
import * as keyframe from 'service/model/widget/keyframe'; // 자주 사용되는 스타일 메이커 가저오기

// -- 기타 함수 불러오기 -- ('service/model/widget/[스타일이름]') 위치로 가저올 수 있습니다.
// import * as [이름] from 'service/[경로]'; 


////////////////////////////////////////////////////////
// 컴포넌트 정의
// 해당 위젯 모댈과 연결할 리엑트 컴포넌트를 정의 합니다.
////////////////////////////////////////////////////////
// export const Component = [가저올 컴포넌트] ; 
export const Component = Timer; 

////////////////////////////////////////////////////////
// 레퍼런스 정의
// 해당 위젯의 기본 설정값을 넣습니다.
////////////////////////////////////////////////////////

export const Reference = {
  position: {x: 20, y: 20, rotate  : 0,},
  size: {width: 400, height: 120},
  meta: {autoplay : true},
  style : {
    fontSize : 50,
    fontFamily : '프리텐다드Black',
    shadowValue: "0", 
    shadowDirection : "soft",
    shadowColor: "#000000",
    color : "#000000",
    ...common.filterDefault,
    ...common.transformDefault,
  },
  value: {
    time : 300,
    endTime : null,
    timerType : 'start',
    option: null
  },
  
  display:{
    show : true
  },
  animation:{
    mountStart:{keyFrame:null,delay:null,timing:null},
    mount:{keyFrame:null,delay:null,timing:null},
    mountEnd:{keyFrame:null,delay:null,timing:null},
    morphing:true
  },
  preset : 'static'
}

////////////////////////////////////////////////////////
// 스타일 정의
// 해당 위젯의 CSS 스타일을 정의합니다.
////////////////////////////////////////////////////////
export function style(data) {
  let layout = {}
  let object = {}
  let property = Object.keys(data)
  if(property.includes('position')){
    layout = Object.assign(layout,common.position({widget:data.position}))
    object = Object.assign(object,common.transform({widget:data.position}))
  }
  if(property.includes('size')){
    object = Object.assign(object,common.size({widget:data.size}))
  }
  if(property.includes('style')){
    // 외곽선 
    object = Object.assign(object,common.border({widget:data.style}))
    // 폰트
    object = Object.assign(object,common.font({widget:data.style}))
    // 필터 + 그림자 + 투명도
    object = Object.assign(object,common.filter({widget:data.style}))
    // 모핑
    layout = Object.assign(layout,common.morphing({widget:data.animation}))
  }

  return {layout,object}
  }
  
////////////////////////////////////////////////////////
// 프리셋 정의
// 해당 위젯의 CSS  프리셋을 정의합니다.
////////////////////////////////////////////////////////
// 프리셋을 사용하지 않는 위젯의 경우
// export const Preset = null
// 프리셋을 사용하는 위젯의 경우
export const Preset = [
    {name:'static', nameKo:'기본타이머', size:{width: 1920, height: 108},position:{x: 0, y: 0,}},
]

////////////////////////////////////////////////////////
// 애니메이션 정의
// 해당 위젯의 기본 설정값을 넣습니다.
////////////////////////////////////////////////////////


/**
 * 사용 가능한 애니메이션 효과를 정의합니다.
 * @param {*} state // 애니메이션 효과 종류 mountStart&mountEnd
 * @param {*} preset // 위젯의 프리셋
 * @returns 
 */
export function availableAnimation (state,preset) {

  const List = keyframe.basic(state)

  let customList = {}

  switch (preset) {
    case 'presetName':
      if(state == 'mountStart'){
        customList = {
          'custom' :  {
            info : {name:'fade in',nameKo:'페이드인',icon:'blur_on'},
            timing : {max:5000,min:500,default:1000},
            target :{layout:{keyframe:'widget_animation_fadeIn'}},
          },
        }
      }

      else if(state == 'mountEnd'){
        customList = {
          'custom' :  {
            info : {name:'fade in',nameKo:'페이드인',icon:'blur_on'},
            timing : {max:5000,min:500,default:1000},
            target :{layout:{keyframe:'widget_animation_fadeIn'}},
          },
        }
      }
      break;
    default:
      break;
  }

  const MergedList = Object.assign( customList, List );
  return MergedList;
}
/**
 * animation 애니메이션 효과를 정의합니다.
 * @param {*} state // 애니메이션 효과 종류 mountStart&mountEnd
 * @param {*} data  // 위젯의 데이터
 * @param {*} preset // 해당 프리셋에서 유효한 애니메이션인지 검증합니다. 
 * @returns 
 */
export function animation (state,data,preset=null) {
  let output = {}
  switch (state) {
    case 'mountStart':

    const List = availableAnimation(state,preset)
    output = keyframe.css({widgetAnimation:data.animation[state],availableAnimation:List})
    break;

    default:
      break;
  }
  return output
}

////////////////////////////////////////////////////////
// 커스텀 펑션 정의
// 해당 위젯 및 위젯 모델에서 사용하는 평선을 정의합니다.
////////////////////////////////////////////////////////
export function autoPlay({widget}) {

  if(!widget){
  return null
  }


  const Data = widget?.data
  console.log('autoPlay : ',Data,Data?.timerType)

  switch (Data?.value?.timerType) {
    case "start":
      console.log('autoPlay A :', {pauseTime:0,timerState:'play',atStart:Date.now(),atEnd:0})
      return {pauseTime:0,timerState:'play',atStart:Date.now(),atEnd:0}

    case "end":

      const now = new Date(); // 현재 시간
      const startOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate()); // 오늘 자정
      const secondsElapsed = (now.getTime() - startOfDay.getTime()) / 1000; // 밀리초 차이를 초로 변환  
      
      if(Data?.value?.endTime >=Math.floor(secondsElapsed)){
        console.log('autoPlay B :', {pauseTime:0,timerState:'play',atStart:Date.now(),atEnd:Data?.value?.endTime})
        return {pauseTime:0,timerState:'play',atStart:Date.now(),atEnd:Data?.value?.endTime}
      }
      if(Data?.value?.endTime < Math.floor(secondsElapsed)){
        console.log('autoPlay C :',  {pauseTime:0,timerState:'play',atStart:Date.now(),atEnd:Data?.value?.endTime+86400})
        return {pauseTime:0,timerState:'play',atStart:Date.now(),atEnd:Data?.value?.endTime+86400}

      }
      break;
  
    default:
      break;
  }
}

// const endTimeControl = (time) => {

//   const now = new Date(); // 현재 시간
//   const startOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate()); // 오늘 자정
//   const secondsElapsed = (now.getTime() - startOfDay.getTime()) / 1000; // 밀리초 차이를 초로 변환       
  
//   if(time >=Math.floor(secondsElapsed)){
//       console.log('체크1',time)
//       setAtEnd(time)
//       // context.setChannelCache({pauseTime:PauseTime,timerState:TimerState,atStart:AtStart,atEnd:time},uuid);
//   }
//   if(time < Math.floor(secondsElapsed)){
//       console.log('체크2',time + 86400)
//       setAtEnd(time + 86400)
//       // context.setChannelCache({pauseTime:PauseTime,timerState:TimerState,atStart:AtStart,atEnd:time+ 86400},uuid);
//   }
// }
