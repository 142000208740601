////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component , Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// [리덕스]스토어 연결
import store from "store";
// [프로바이더] 연결
import EditContext from 'service/context/EditContext' ; 

// SASS&CSS 연결
import "sass/widget.scss"

// 서비스 연결
import * as Event from '../../../service/event/Actions' ; 
import * as Widget from '../../../service/event/widget' ; 
import * as Utility from'service/other/Utility.js' ; 
import * as Model from '../../../service/value/Model' ; 
import * as EnCoder from '../../../service/engine/EnCoder' ; 
import testUtils from 'react-dom/test-utils';

//이미지 
import hide from 'assets/resource/hide.png';
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
class WidgetOverRay extends Component {
    static contextType = EditContext;
    state = {
        dragX : false,
        dragY : false,
        layout : {width : 0, height : 0,top : 0,left : 0,},
        shiftKey : false,
        oldWidth:this.props.target.data.size.width,
        oldHeight:this.props.target.data.size.height,
        new :true,
    }

    constructor(props) {
        super(props);
        this.overRayRactLeftTop = React.createRef();
        this.overRayRactRightTop = React.createRef();
        this.overRayRactLeftBottom = React.createRef();
        this.overRayRactRightBottom = React.createRef();

        this.overRayRactTop = React.createRef();
        this.overRayRactRight = React.createRef();
        this.overRayRactLeft = React.createRef();
        this.overRayRactBottom = React.createRef();
      }
    
    overRayTransform = (property,subName=null) =>{
        if (subName){
            subName =subName+'_'
          }else{
            subName = ''
          }
          let keys = Object.keys(property)
          let result =''
        
          // 필터
          if(keys.includes(subName+'rotateZ')){
            result += 'rotateZ('+subName+property['rotateZ']+'deg) '
          }
          if(keys.includes(subName+'scale')){
            result += 'scale('+subName+property['scale']/100+','+subName+property['scale']/100+') '
          }
          return {transform : result}
    }

    //////////////////////////////////////////////////
    drag = (e,type) =>{
        if(e.pageX!=0 && e.pageY!=0){
            let changeValue = Widget.coordinateSystem(this.context,type,e,this.state.dragX,this.state.dragY);
            e.stopPropagation()
            this.overRayChange(changeValue.size.width,changeValue.size.height,changeValue.position.y,changeValue.position.x,e.shiftKey)
        }
    }

    dragStart = (e) =>{
        Event.setDragNoneImage(this,e)
        this.setState({
            dragX : e.pageX, 
            dragY : e.pageY,
        })
        e.stopPropagation()
    }

    dragEnd = (e,type) =>{
        let changeValue = Widget.widgetSizeChange(this.context,type,e.pageX,e.pageY,this.state.dragX,this.state.dragY,this.state.shiftKey)
        e.stopPropagation()
        Event.deleteDragImage()
        this.overRayChange(changeValue.size.width,changeValue.size.height)
    }
    //////////////////////////////////////////////////
    // 알트 모드시
    //////////////////////////////////////////////////
    alt_drag = (e,type) =>{
        if(e.pageX!=0 && e.pageY!=0){
            let changeValue = Widget.coordinateSystem(this.context,type,e,this.state.dragX,this.state.dragY)
            e.stopPropagation()
            this.alt_overRayChange(changeValue.size.width,changeValue.size.height,changeValue.position.y,changeValue.position.x,e.shiftKey,e.pageX,e.pageY)
        }
    }

    alt_dragStart = (e) =>{
        Event.setDragNoneImage(this,e)
        this.setState({
            dragX : e.pageX,
            dragY : e.pageY,
        },this.props.DefaultMethod('dragLock',true))
        e.stopPropagation()
    }

    alt_dragEnd = (e,type) =>{
        this.props.DefaultMethod('dragLock',true)
        let changeValue = Widget.widgetSizeChange(this.props.target.id,type,this.state.pageX,this.state.pageY,this.state.dragX,this.state.dragY,this.state.shiftKey)
        e.stopPropagation()
        Event.deleteDragImage()
        this.overRayChange(changeValue.size.width,changeValue.size.height)
    }

    overRaySize = (moveWidth=0,moveHeight=0,moveTop=0,moveLeft=0) =>{

        //
        // if (String(widget.size.width).indexOf('%')!=-1){
            //     width =(this.props.broadCast.size.width/100*Number(width.substring(0,(width).indexOf('%'))))
            // }
        
        let width = this.width()
        let height = this.height()

        if(this.props.object){
            width  = Number(this.props.object.clientWidth) 
            height = Number(this.props.object.clientHeight)
        }
        this.setState({
            layout : {
                width :  width + moveWidth,
                height : height + moveHeight,
                top : moveTop,
                left : moveLeft,
            },
        })
    }

    overRayChange = (moveWidth=0,moveHeight=0,moveTop=0,moveLeft=0,shiftKey=false) =>{
        moveWidth = Number(moveWidth)
        moveHeight = Number(moveHeight)
        moveTop = Number(moveTop)
        moveLeft = Number(moveLeft)
        this.setState({
            layout : {
                width : moveWidth,
                height : moveHeight,
                top : moveTop,
                left : moveLeft,
            },
            shiftKey
        })
    }
    alt_overRayChange = (moveWidth=0,moveHeight=0,moveTop=0,moveLeft=0,shiftKey=false,pageX,pageY) =>{
        moveWidth = Number(moveWidth)
        moveHeight = Number(moveHeight)
        moveTop = Number(moveTop)
        moveLeft = Number(moveLeft)
        this.setState({
            pageX : pageX,
            pageY : pageY, 
            layout : {
                width : moveWidth,
                height : moveHeight,
                top : moveTop,
                left : moveLeft,
            },
            shiftKey
        })
    }

    width = ()=>{
        let width = this.props.target.data.size.width
        if (String(this.props.target.data.size.width).indexOf('%')!=-1){
            width =(this.context.overlap.meta.resolution.width/100*Number(width.substring(0,(width).indexOf('%'))))
        }
        return (Number(width))
    }

    height = () =>{
        let height = this.props.target.data.size.height
        if (String(this.props.target.data.size.height).indexOf('%')!=-1){
            height =(this.context.overlap.meta.resolution.height/100*Number(height.substring(0,(height).indexOf('%'))))
        }
        return (Number(height))
    }


    componentDidMount=()=>{
        this.overRaySize()
    }


    componentDidUpdate() {
        let width = this.width()
        let height = this.height()
        if(this.props.object){
            width  = Number(this.props.object.clientWidth) 
            height = Number(this.props.object.clientHeight)
        }

        if(width !=Number(this.state.oldWidth)||height !=Number(this.state.oldHeight) ){
            try{
                this.setState({
                    oldWidth: width,
                    oldHeight:height,
                },this.overRaySize) 
            }
            catch{
                return
            }

        }
    }

    widgetState = () => {
        let show
        try{
            show = this.props.target.data.display.show

        }catch{
            show = false
        }
        let list = [] 
        if(!show){
            list.push(
                <li>
                    <img src={hide}/>
                </li>
            )
        }
        return (
            <ul className="widgetState" >
              {list}
            </ul>       
        )

    }

    overRay = () =>{
        let transform = this.overRayTransform(this.props.target.data.style)
        let layout =null
        if(this.props.userState.browser== "firefox"&&"safari"){
            switch (this.props.mode) {
                case "8_Direction":
                    layout = (
                        <Fragment>
                            <div style={{top:'-10px',left:'-10px'}} ref={this.overRayRactLeftTop} id="overRayRactLeftTop" className='overRayRact' tabIndex={0} draggable="true" 
                                onDragOver={(e)=>this.alt_drag(e,'LeftTop')} 
                                onDragStart={(e) => this.alt_dragStart(e)} 
                                onDragEnd={(e) => this.alt_dragEnd(e,'LeftTop')} 
                            />
                            <div style={{top:'-10px',right:'-10px'}} ref={this.overRayRactRightTop} id="overRayRactRightTop" className='overRayRact' tabIndex={0} draggable="true"
                                onDragOver={(e)=>this.alt_drag(e,'RightTop')} 
                                onDragStart={(e) => this.alt_dragStart(e)} 
                                onDragEnd={(e) => this.alt_dragEnd(e,'RightTop')} 
                            />
                            <div style={{bottom:'-10px',left:'-10px'}} ref={this.overRayRactLeftBottom} id="overRayRactLeftBottom" className='overRayRact' tabIndex={0} draggable="true"
                                onDragOver={(e)=>this.alt_drag(e,'LeftBottom')} 
                                onDragStart={(e) => this.alt_dragStart(e)} 
                                onDragEnd={(e) => this.alt_dragEnd(e,'LeftBottom')} 
                            />
                            <div style={{bottom:'-10px',right:'-10px'}} ref={this.overRayRactRightBottom} id="overRayRactRightBottom" className='overRayRact' tabIndex={0} draggable="true"
                                onDragOver={(e)=>this.alt_drag(e,'RightBottom')} 
                                onDragStart={(e) => this.alt_dragStart(e)} 
                                onDragEnd={(e) => this.alt_dragEnd(e,'RightBottom')} 
                            />
                            <div style={{top:'-10px',left:'50%',transform:'translateX(-50%)'}} ref={this.overRayRactTop} id="overRayRactTop" className='overRayRact' tabIndex={0} draggable="true" 
                                onDragOver={(e)=>this.alt_drag(e,'Top')} 
                                onDragStart={(e) => this.alt_dragStart(e)} 
                                onDragEnd={(e) => this.alt_dragEnd(e,'Top')} 
                            />
                            <div style={{top:'50%',right:'-10px',transform:'translateY(-50%)'}} ref={this.overRayRactRight} id="overRayRactRight" className='overRayRact' tabIndex={0} draggable="true"
                                onDragOver={(e)=>this.alt_drag(e,'Right')} 
                                onDragStart={(e) => this.alt_dragStart(e)} 
                                onDragEnd={(e) => this.alt_dragEnd(e,'Right')} 
                            />
                            <div style={{top:'50%',left:'-10px',transform:'translateY(-50%)'}} ref={this.overRayRactLeft} id="overRayRactLeft" className='overRayRact' tabIndex={0} draggable="true"
                                onDragOver={(e)=>this.alt_drag(e,'Left')} 
                                onDragStart={(e) => this.alt_dragStart(e)} 
                                onDragEnd={(e) => this.alt_dragEnd(e,'Left')} 
                            />
                            <div style={{bottom:'-10px',left:'50%',transform:'translateX(-50%)'}} ref={this.overRayRactBottom} id="overRayRactBottom" className='overRayRact' tabIndex={0} draggable="true"
                                onDragOver={(e)=>this.alt_drag(e,'Bottom')} 
                                onDragStart={(e) => this.alt_dragStart(e)} 
                                onDragEnd={(e) => this.alt_dragEnd(e,'Bottom')} 
                            />   
                        </Fragment>
                    )
                    break
                case "4_Direction":
                    layout = (
                        <Fragment>
                            <div style={{top:'-10px',left:'-10px'}} ref={this.overRayRactLeftTop} id="overRayRactLeftTop" className='overRayRact' tabIndex={0} draggable="true" 
                                onDragOver={(e)=>this.alt_drag(e,'RatioLeftTop')} 
                                onDragStart={(e) => this.alt_dragStart(e)} 
                                onDragEnd={(e) => this.alt_dragEnd(e,'RatioLeftTop')} 
                            />
                            <div style={{top:'-10px',right:'-10px'}} ref={this.overRayRactRightTop} id="overRayRactRightTop" className='overRayRact' tabIndex={0} draggable="true"
                                onDragOver={(e)=>this.alt_drag(e,'RatioRightTop')}
                                onDragStart={(e) => this.alt_dragStart(e)} 
                                onDragEnd={(e) => this.alt_dragEnd(e,'RatioRightTop')} 
                            />
                            <div style={{bottom:'-10px',left:'-10px'}} ref={this.overRayRactLeftBottom} id="overRayRactLeftBottom" className='overRayRact' tabIndex={0} draggable="true"
                                onDragOver={(e)=>this.alt_drag(e,'RatioLeftBottom')}
                                onDragStart={(e) => this.alt_dragStart(e)} 
                                onDragEnd={(e) => this.alt_dragEnd(e,'RatioLeftBottom')} 
                            />
                            <div style={{bottom:'-10px',right:'-10px'}} ref={this.overRayRactRightBottom} id="overRayRactRightBottom" className='overRayRact' tabIndex={0} draggable="true"
                                onDragOver={(e)=>this.alt_drag(e,'RatioRightBottom')}
                                onDragStart={(e) => this.alt_dragStart(e)} 
                                onDragEnd={(e) => this.alt_dragEnd(e,'RatioRightBottom')} 
                            />
                        </Fragment>
                    )
                    break
                case "2_DirectionTopDown":
                    layout = (
                        <Fragment>
                            <div style={{top:'-10px',left:'50%',transform:'translateX(-50%)'}} ref={this.overRayRactTop} id="overRayRactTop" className='overRayRact' tabIndex={0} draggable="true" 
                                onDragOver={(e)=>this.alt_drag(e,'Top')} 
                                onDragStart={(e) => this.alt_dragStart(e)} 
                                onDragEnd={(e) => this.alt_dragEnd(e,'Top')} 
                            />
                            <div style={{bottom:'-10px',left:'50%',transform:'translateX(-50%)'}} ref={this.overRayRactBottom} id="overRayRactBottom" className='overRayRact' tabIndex={0} draggable="true"
                                onDragOver={(e)=>this.alt_drag(e,'Bottom')} 
                                onDragStart={(e) => this.alt_dragStart(e)} 
                                onDragEnd={(e) => this.alt_dragEnd(e,'Bottom')} 
                            />   
                        </Fragment>
                    )
                    break
                case "2_DirectionLeftRight":
                    layout = (
                        <Fragment>
                            <div style={{top:'50%',right:'-10px',transform:'translateY(-50%)'}} ref={this.overRayRactRight} id="overRayRactRight" className='overRayRact' tabIndex={0} draggable="true"
                                onDragOver={(e)=>this.alt_drag(e,'Right')} 
                                onDragStart={(e) => this.alt_dragStart(e)} 
                                onDragEnd={(e) => this.alt_dragEnd(e,'Right')} 
                            />
                            <div style={{top:'50%',left:'-10px',transform:'translateY(-50%)'}} ref={this.overRayRactLeft} id="overRayRactLeft" className='overRayRact' tabIndex={0} draggable="true"
                                onDragOver={(e)=>this.alt_drag(e,'Left')} 
                                onDragStart={(e) => this.alt_dragStart(e)} 
                                onDragEnd={(e) => this.alt_dragEnd(e,'Left')} 
                            />
                        </Fragment>
                    )
                    break;
                default:
                    break;
            }
        }else{
            switch (this.props.mode) {
                case "8_Direction":
                    layout = (
                        <Fragment>
                            <div style={{top:'-10px',left:'-10px'}} ref={this.overRayRactLeftTop} id="overRayRactLeftTop" className='overRayRact' tabIndex={0} draggable="true" 
                                onDrag={(e)=>this.drag(e,'LeftTop')} 
                                onDragStart={(e) => this.dragStart(e)} 
                                onDragEnd={(e) => this.dragEnd(e,'LeftTop')} 
                            />
                            <div style={{top:'-10px',right:'-10px'}} ref={this.overRayRactRightTop} id="overRayRactRightTop" className='overRayRact' tabIndex={0} draggable="true"
                                onDrag={(e)=>this.drag(e,'RightTop')} 
                                onDragStart={(e) => this.dragStart(e)} 
                                onDragEnd={(e) => this.dragEnd(e,'RightTop')} 
                            />
                            <div style={{bottom:'-10px',left:'-10px'}} ref={this.overRayRactLeftBottom} id="overRayRactLeftBottom" className='overRayRact' tabIndex={0} draggable="true"
                                onDrag={(e)=>this.drag(e,'LeftBottom')} 
                                onDragStart={(e) => this.dragStart(e)} 
                                onDragEnd={(e) => this.dragEnd(e,'LeftBottom')} 
                            />
                            <div style={{bottom:'-10px',right:'-10px'}} ref={this.overRayRactRightBottom} id="overRayRactRightBottom" className='overRayRact' tabIndex={0} draggable="true"
                                onDrag={(e)=>this.drag(e,'RightBottom')} 
                                onDragStart={(e) => this.dragStart(e)} 
                                onDragEnd={(e) => this.dragEnd(e,'RightBottom')} 
                            />
                            <div style={{top:'-10px',left:'50%',transform:'translateX(-50%)'}} ref={this.overRayRactTop} id="overRayRactTop" className='overRayRact' tabIndex={0} draggable="true" 
                                onDrag={(e)=>this.drag(e,'Top')} 
                                onDragStart={(e) => this.dragStart(e)} 
                                onDragEnd={(e) => this.dragEnd(e,'Top')} 
                            />
                            <div style={{top:'50%',right:'-10px',transform:'translateY(-50%)'}} ref={this.overRayRactRight} id="overRayRactRight" className='overRayRact' tabIndex={0} draggable="true"
                                onDrag={(e)=>this.drag(e,'Right')} 
                                onDragStart={(e) => this.dragStart(e)} 
                                onDragEnd={(e) => this.dragEnd(e,'Right')} 
                            />
                            <div style={{top:'50%',left:'-10px',transform:'translateY(-50%)'}} ref={this.overRayRactLeft} id="overRayRactLeft" className='overRayRact' tabIndex={0} draggable="true"
                                onDrag={(e)=>this.drag(e,'Left')} 
                                onDragStart={(e) => this.dragStart(e)} 
                                onDragEnd={(e) => this.dragEnd(e,'Left')} 
                            />
                            <div style={{bottom:'-10px',left:'50%',transform:'translateX(-50%)'}} ref={this.overRayRactBottom} id="overRayRactBottom" className='overRayRact' tabIndex={0} draggable="true"
                                onDrag={(e)=>this.drag(e,'Bottom')} 
                                onDragStart={(e) => this.dragStart(e)} 
                                onDragEnd={(e) => this.dragEnd(e,'Bottom')} 
                            />   
                        </Fragment>
                    )
                    break
                case "4_Direction":
                    layout = (
                        <Fragment>
                            <div style={{top:'-10px',left:'-10px'}} ref={this.overRayRactLeftTop} id="overRayRactLeftTop" className='overRayRact' tabIndex={0} draggable="true" 
                                onDrag={(e)=>this.drag(e,'RatioLeftTop')} 
                                onDragStart={(e) => this.dragStart(e)} 
                                onDragEnd={(e) => this.dragEnd(e,'RatioLeftTop')} 
                            />
                            <div style={{top:'-10px',right:'-10px'}} ref={this.overRayRactRightTop} id="overRayRactRightTop" className='overRayRact' tabIndex={0} draggable="true"
                                onDrag={(e)=>this.drag(e,'RatioRightTop')}
                                onDragStart={(e) => this.dragStart(e)} 
                                onDragEnd={(e) => this.dragEnd(e,'RatioRightTop')} 
                            />
                            <div style={{bottom:'-10px',left:'-10px'}} ref={this.overRayRactLeftBottom} id="overRayRactLeftBottom" className='overRayRact' tabIndex={0} draggable="true"
                                onDrag={(e)=>this.drag(e,'RatioLeftBottom')}
                                onDragStart={(e) => this.dragStart(e)} 
                                onDragEnd={(e) => this.dragEnd(e,'RatioLeftBottom')} 
                            />
                            <div style={{bottom:'-10px',right:'-10px'}} ref={this.overRayRactRightBottom} id="overRayRactRightBottom" className='overRayRact' tabIndex={0} draggable="true"
                                onDrag={(e)=>this.drag(e,'RatioRightBottom')}
                                onDragStart={(e) => this.dragStart(e)} 
                                onDragEnd={(e) => this.dragEnd(e,'RatioRightBottom')} 
                            />
                        </Fragment>
                    )
                    break
                case "2_DirectionTopDown":
                    layout = (
                        <Fragment>
                            <div style={{top:'-10px',left:'50%',transform:'translateX(-50%)'}} ref={this.overRayRactTop} id="overRayRactTop" className='overRayRact' tabIndex={0} draggable="true" 
                                onDrag={(e)=>this.drag(e,'Top')} 
                                onDragStart={(e) => this.dragStart(e)} 
                                onDragEnd={(e) => this.dragEnd(e,'Top')} 
                            />
                            <div style={{bottom:'-10px',left:'50%',transform:'translateX(-50%)'}} ref={this.overRayRactBottom} id="overRayRactBottom" className='overRayRact' tabIndex={0} draggable="true"
                                onDrag={(e)=>this.drag(e,'Bottom')} 
                                onDragStart={(e) => this.dragStart(e)} 
                                onDragEnd={(e) => this.dragEnd(e,'Bottom')} 
                            />   
                        </Fragment>
                    )
                    break
                case "2_DirectionLeftRight":
                    layout = (
                        <Fragment>
                            <div style={{top:'50%',right:'-10px',transform:'translateY(-50%)'}} ref={this.overRayRactRight} id="overRayRactRight" className='overRayRact' tabIndex={0} draggable="true"
                                onDrag={(e)=>this.drag(e,'Right')} 
                                onDragStart={(e) => this.dragStart(e)} 
                                onDragEnd={(e) => this.dragEnd(e,'Right')} 
                            />
                            <div style={{top:'50%',left:'-10px',transform:'translateY(-50%)'}} ref={this.overRayRactLeft} id="overRayRactLeft" className='overRayRact' tabIndex={0} draggable="true"
                                onDrag={(e)=>this.drag(e,'Left')} 
                                onDragStart={(e) => this.dragStart(e)} 
                                onDragEnd={(e) => this.dragEnd(e,'Left')} 
                            />
                        </Fragment>
                    )
                    break;
                default:
                    break;
            }
        }
        return layout
    }


    render() {
        let transform = this.overRayTransform(this.props.target.data.style)
        return (
            <div className='overRay' style={{...transform,...this.state.layout}} >
                {this.widgetState()}
                {this.overRay()}
            </div>
        )
        
       
    }
}
////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////


////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    broadCast : state.broadCast,
    userState : state.userState,
})

function mapDispatchToProps(dispatch){
    return {

    }
}

  // export default Login;
  export default connect(mapStateToProps,mapDispatchToProps)(WidgetOverRay);
  