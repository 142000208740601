// 서비스 연결
import _ from 'lodash'; // lodash import
import * as Utility from 'service/other/Utility';
import * as widgetModel from 'service/model/widgetModel'
import * as EeCoder from 'service/engine/EnCoder'; 
import * as DeCoder from 'service/engine/DeCoder' ;  

// 이미지 연결
import textIcon from 'assets/widget/text.png';

// 위젯 목록
export const WidgetDefine = [
{title : '레거시텍스트', image : textIcon, icon:'title', widget : 'Text', option : {'test':true}, version : 1, reference: {}, category : ['basic','text'], tag : []},
// {title : '테스트텍스트', image : null, icon:'build_circle', widget : 'Text', option : {}, version : 0, reference: {}, category : ['basic','text'], tag : []},
{title : '텍스트', image : null, icon:'title', widget : 'Text', option : {'test':false}, version : 2, reference: {}, category : ['basic','text'], tag : []},
{title : '이미지', image : null, icon:'image', widget : 'Image', option : {}, reference: {}, category : ['basic','file'], tag : []},
{title : '동영상', image : null, icon:'smart_display', widget : 'Video', option : {}, reference: {}, category : ['basic','file'], tag : []},
{title : '카메라', image : null, icon:'videocam', widget : 'Camera', option : {'test':true}, reference: {}, category : ['basic','file'], tag : []},
{title : '채팅', image : null, icon:'sms', widget : 'Chat', option : {}, reference: {}, category : ['basic','utility','live'], tag : []},
{title : '메모', image : null, icon:'note_stack', widget : 'Memo', option : {}, reference: {}, category : ['basic','text'], tag : []},
{title : '타이머', image : null, icon:'hourglass_top', widget : 'Timer', option : {}, reference: {}, category : ['basic','utility'], tag : []},
{title : '스탑워치', image : null, icon:'timer', widget : 'StopWatch', option : {'test':true}, reference: {}, category : ['basic','utility'], tag : []},
// {title : '시계', image : null, icon:'nest_clock_farsight_digital', widget : 'Clock', option : {}, reference: {}, category : ['basic','utility'], tag : []},
{title : '삼각형', image : null, icon:'change_history', widget : 'Shape', option : {preset:'triangle'}, reference: {}, category : ['basic','shape'], tag : []},
{title : '직각삼각형', image : null, icon:'signal_cellular_4_bar', widget : 'Shape', option : {preset:'rightTriangle'}, reference: {}, category : ['basic','shape'], tag : []},
{title : '사각형', image : null, icon:'square', widget : 'Shape', option : {preset:'quadrangle'}, reference: {}, category : ['basic','shape'], tag : []},
// {title : '오각형', image : null, icon:'pentagon', widget : 'Shape', option : {preset:'pentagon'}, reference: {}, category : ['basic','shape'], tag : []},
{title : '육각형', image : null, icon:'hexagon', widget : 'Shape', option : {preset:'hexagon'}, reference: {}, category : ['basic','shape'], tag : []},
{title : '원', image : null, icon:'circle', widget : 'Shape', option : {preset:'circle'}, reference: {}, category : ['basic','shape'], tag : []},
// {title : '별', image : null, icon:'star', widget : 'Shape', option : {preset:'star'}, reference: {}, category : ['basic','shape'], tag : []},

{title : '스코어보드', image : null, icon:'scoreboard', widget : 'Scoreboard', option : {positionX:0,positionY:10}, reference: {}, category : ['basic','utility','game'], tag : []},

{title : '밴픽', image : null, icon:'how_to_reg', widget : 'BanPick', option : {positionX:0,positionY:730}, reference: {}, category : ['basic','utility','game'], tag : []},
// {title : '가져오기', image : null, icon:'input', widget : 'Import', option : {}, reference: {}, category : ['basic','shape'], tag : []},
// {title : 'PDF', image : null, icon:'picture_as_pdf', widget : 'PDFwidget', option : {}, reference: {}, category : ['basic','file'], tag : []},
{title : '스크롤 자막', image : null, icon:'text_rotation_none', widget : 'ScrollCaption', option : {positionX:0,positionY:1010}, reference: {}, category : ['basic','text'], tag : []},
{title : '파티클', image : null, icon:'snowing', widget : 'Particles', option : {'test':true}, reference: {}, category : ['basic','shape'], tag : []},
{title : '배경화면', image : null, icon:'background_grid_small', widget : 'Background', option : {}, reference: {}, category : ['basic','shape','effect'], tag : []},

// {title : '사각형', image : null, icon:'nest_clock_farsight_digital', widget : 'Shape', option : {}, category : ['basic','shape'], tag : []},
] 