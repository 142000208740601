////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { useState, useEffect, useRef, useCallback, Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/widgetEdit.scss"

// 컴포넌트 연결
import ImageButtonModule from '../editModule/ImageButtonModule'; // 이미지 버튼을 눌러 여러 옵션중 값을 정합니다.
import ErrorModule from '../editModule/ErrorModule'; // 잘못된 위젯의 값이 있을때 편집기를 대체합니다.

import _ from 'lodash'; // lodash import
// 서비스 연결
import * as Editor from 'service/edit/EditService'; 
import * as ED from 'service/engine/decoder/EditorDecoder'; 
// 이미지
import round_0 from 'assets/sidebar/other/round_0.png';
import round_1 from 'assets/sidebar/other/round_1.png';
import round_2 from 'assets/sidebar/other/round_2.png';
import round_3 from 'assets/sidebar/other/round_3.png';
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////


/** [에디터] 텍스트 편집 툴 입니다.
 * (대상위젯, 편집펑션, 편집할 객체 키, 타이틀 ,옵션)
* @param {widget} target : 편집할 대상 위젯
* @param {function} modify : 편집용 함수
* @param {{
* size: { width : number, height : number }
* }} keyName : 해당 에디터가 수정하는 값 입니다. value : {'textValue', 수정할 대상값,} 
* @param {{
* editTitle: string,textValue: string
* }} title : 편집기에 표시될 제목들 입니다.
* @param {{
* fixToggle : boolean 
* openToggle : boolean
* }} option : 추가 옵션입니다. fixToggle : 토글을 고정합니다. openToggle : 필드의 기본 상태를 열림으로 봅니다.
*/


function RotateEditForm({target,modify,keyName,title,option}) {

/**
     * 위젯을 수정합니다.
     *  @param {bool} classify : 수정할 값의 분류를 적습니다.
     *  @param {bool} key : 수정할 키의 이름을 적습니다.
     *  @param {bool} value : 해당페이지가 선택한 뷰에서 방송중인지 확인합니다.
     */

    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////

    // set
    const uuid = target.uuid
    const RotateRef = useRef(null); 

    
    // state
    const [Toggle, setToggle] = useState(option?.openToggle==undefined?true:option?.openToggle);
    const [OpenForm, setOpenForm] = useState(option?.openForm==undefined?true:option?.openForm);

    const [center, setCenter] = useState({ x: 0, y: 0 });
    const [isDragging, setIsDragging] = useState(false);
    const [rotateDeg, setDegRotate] = useState(0)
    // rel 
    
    ////////////////////////////////////////////////////////
    // Life Cycle
    ////////////////////////////////////////////////////////
    // useEffect(() => {

    //     // 토글의 사용 여부 및 필드의 열림 상태를 정의합니다.

    //     // 여기에 컴포넌트가 마운트되었을 때 실행할 코드를 작성합니다.

    //     return () => {
    //     // 컴포넌트가 언마운트될 때 실행될 함수를 반환할 수 있습니다. 이는 componentWillUnmount와 유사합니다.
    //     };
    //   }, []);


    const modifyRotate = useCallback((e) => {
        modify(rotate.category,rotate.key,e.target.value,uuid)
        setDegRotate(e.target.value)
    }, []);
    

    const throttledHandleChange = useCallback(
        _.debounce(modifyRotate, 100, { leading: true, trailing: true }),
        [modifyRotate]
    );


    useEffect(() => {
        setDegRotate(ED.getValue(target,rotate))
    }, []);

    useEffect(() => {
        // 클린업: 컴포넌트 언마운트 시 스로틀 취소
        return () => {
        throttledHandleChange.cancel();
        };
    }, [throttledHandleChange]);

      useEffect(() => {
        if (RotateRef.current) {
          // 엘리먼트의 크기와 위치 정보를 가져옴
          const rect = RotateRef.current.getBoundingClientRect();
          
          // 뷰포트 기준으로 중앙 좌표 계산
          const centerX = rect.left + rect.width / 2;
          const centerY = rect.top + rect.height / 2;
    
          // 상태 업데이트
          setCenter({ x: centerX, y: centerY });
        }
      }, [isDragging]); // 컴포넌트가 마운트될 때 한 번 실행
    ////////////////////////////////////////////////////////
    // JSX
    ////////////////////////////////////////////////////////

    // 해당 에디터에서 편집이 가능한 값 목록
    const {rotate} = keyName ;

    const Options = {}

    const handleFocus = useCallback((e) => {
        e.target.select();
      }, []);

    if(option.closeEdit){
        Options.closeEdit = (<div className='closeEdit'>
           <input className='closeEdit_rotate' type="number" value={ED.getValue(target,rotate)} onFocus={handleFocus} onChange={(e) =>throttledHandleChange(e)}/>°
        </div>)
    }

    const calculateAngle = (x1, y1, x2, y2) => {
        const deltaX = x2 - x1;
        const deltaY = y2 - y1;
        const angleInRadians = Math.atan2(deltaY, deltaX);
      
        // 라디안 → 도 단위 변환 후 기준 조정
        let angle = Math.floor(angleInRadians * (180 / Math.PI)) + 90;
      
        // 0° ~ 359° 범위로 보정
        angle = ((angle % 360) + 360) % 360;
      
        return angle;
      };
      

    const handleMouseMove = (e) => {
        if(center&& isDragging){
            const computedAngle = calculateAngle(center?.x, center?.y, e.clientX, e.clientY);
            console.log('computedAngle',Math.floor(computedAngle));
            setDegRotate(Math.floor(computedAngle))
            modify(rotate.category,rotate.key,Math.floor(computedAngle),uuid)
        }
    }

    if(ED.getValue(target,rotate) == undefined){
        return null
    }

    return (
    <Fragment>
        <style jsx>{`${FormStyle}`}</style>
        <section className='RotateEditForm '>
            {Editor.editorDetail('회전','sync',OpenForm,setOpenForm,Toggle,Options)}
            <div className={OpenForm?'editContainer containerOpen':'editContainer containerClose'}>
                {/* {Editor.editorSubTitle('텍스트 설정',"edit_note")} */}
                <article className="EditPart" >
                <div className='RotateEditModule'>
                    <p>
                    각도 조정
                    </p>
           
                    {/* <ImageButtonModule value={ED.getValue(target,rotate)} type={rotate?.key} title="각도 조절" option={{fontSize:10}} data={[{val:0,text:'0°',title:"0도 회전 시키기"},{val:90,text:'90°',title:"90도 회전 시키기"},{val:180,text:'180°',title:"180도 회전 시키기"},{val:270,text:'270°',title:"270도 회전 시키기"}]}  change={(type,value)=>{modify(rotate?.category,type,value,uuid);setDegRotate(value)}}/> */}
                </div>
                <div className='RotateContainer'>
                    <div className='RotateInput'>
                        <input type="number" value={ED.getValue(target,rotate)} onChange={(e) =>throttledHandleChange(e)}/>°

                    </div>
                    <div className='cycleContainer' ref={RotateRef} onMouseMove={handleMouseMove} onMouseDown={()=>setIsDragging(true)}  onMouseLeave={()=>setIsDragging(false)} onMouseUp={()=>setIsDragging(false)} >
                        <div className='cycle'/>
                        <div className='controlBox'  style={{transform : `rotate(${rotateDeg}deg)`}}  >
                            <div className='crown'/>
                        </div>
                    </div>
                </div>

                
         
                {/* {rotate?<ImageButtonModule value={ED.getValue(target,borderRadius)} type={borderRadius?.key} data={[{val:MaxRadius,image:round_3,text:'text',title:"원형모서리"},{val:25,image:round_2,title:"둥근모서리"},{val:10,image:round_1,title:"약간 둥근모서리"},{val:0,image:round_0,title:"각진모서리"}]} title={borderRadius?.title??"둥글게"} change={(type,value)=>modify(borderRadius?.category,type,value,uuid)}/>:''} */}
                </article>
            </div>
        </section>
    </Fragment>
    );

}

export default RotateEditForm;


const FormStyle = `

.RotateEditForm .closeEdit{
    height: 25px;
    display:flex;
    background-color: #444;
    border-radius :5px;
    padding : 0px;
}
.RotateEditForm .EditPart{
    display: flex;
    justify-content: space-between;
}

.RotateEditForm .closeEdit .ShortButtonList{
    display : flex; 
    width: 110px;
    justify-content: space-around;
    align-items: center;
 
 }
 .RotateEditForm .closeEdit .shortButton{
     width: 20px;
     height: 20px;
     display : flex;
     align-items: center; 
     justify-content: center;
 }

 .RotateEditForm .closeEdit .shortButtonSelect{
    background-color: #262626;
    border-radius : 2.5px;
}

.RotateEditForm .RotateContainer{
    width: 150px;
    height: 50px;
    background-color: #222;
    border-radius : 5px;
    display : flex;
    justify-content: space-between;
}

.RotateEditForm .cycleContainer {
    width: 30px;
    height: 30px;
    padding : 10px;
    position: relative;
    align-items: center;
}

.RotateEditForm .RotateInput{
    width: 100px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size : 20px;
}

.RotateEditForm .RotateInput input{
    width: 60px;
    height: 30px;
    background-color: red;
    text-align: right;
    background-color: #222;
    padding: 0px;
    border-radius: 5px;
    color: #fff;
    border: none; 
    outline: none;
    font-size : 20px;
    line-height: 30px;
}

.RotateEditForm .cycle {
    width: 20px;
    height: 20px;
    border-radius : 100%;
    border : 5px solid #777;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: start;
}

.RotateEditForm .controlBox {
    width: 30px;
    height: 30px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: start;
    z-index : 1;
}

.RotateEditForm .crown {
    width: 5px;
    height: 5px;
    border-radius : 100%;
    transform: scale(3);
    background-color: #222;
}


.RotateEditForm .closeEdit_rotate{
    height: 20px;
    width: 40px;
    margin: 0px;
    padding: 0px;
    font-size: 14px;
    line-height: 20px;
    text-align: center;

    outline: none;
    background: none;
    border: none;
    color: #fff;
}

.RotateEditForm .closeEdit{
    display: flex;
    width: 100px;
    padding: 0px 5px;
    align-items: center;
    color: #fff;
    border: none;
    justify-content: center;
}
.RotateEditForm .RotateEditModule{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    flex : 1;
    padding : 5px 0px;
}
.RotateEditForm .RotateEditModule input{
    font-size: 20px;
    /* height: 20px; */
    width: 100px;
    padding: 2px 6px 2px 11px;
    padding: 2px 0px;
    text-align: right;
    background-color: #222;
    padding: 2px 11px;
    border-radius: 5px;
    color: #000;
    color: #fff;
    border: none; 
}



.RotateEditForm .closeEdit input[type="number"]::-webkit-inner-spin-button,
.RotateEditForm .closeEdit input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.RotateEditForm .RotateInput input[type="number"]::-webkit-inner-spin-button,
.RotateEditForm .RotateInput input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

`;