////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { useState, useEffect, useContext, useRef } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
import { Route, Link } from 'react-router-dom';
import { useHistory } from "react-router";
// 라이브러리 

// 설정 파일
import * as config from 'config/OverlapConfig'

// [컴포넌트] 위젯 
import { PositionGuide, ResizeGuide, RotateGuide } from 'components/Edit/editScreen/controlObject/WidgetControllerGuide'
import WidgetOption from 'components/Edit/editScreen/controlObject/WidgetOption'
// [프로바이더]
import EditContext from 'service/context/EditContext';
// import store from "store";

// SASS&CSS 연결
// import 'sass/login.scss'

// 이미지 연결
// import logoW from 'assets/logoW.svg';

// 서비스 연결
import * as DeCoder from 'service/engine/DeCoder';
import * as Widget from 'service/edit/WidgetService';
import * as Utility from 'service/other/Utility';
import * as Editor from 'service/edit/EditService';
import zIndex from '@material-ui/core/styles/zIndex';
import { transform } from 'lodash';

////////////////////////////////////////////////////////
// component 부분
//////////////////////////////////////////////////////////

/** [위젯선택] 
 * 선택된 위젯을 컨트롤 할 수 있는 컨트롤러 입니다.
 * @param {number} x 위젯의 가로 시작 좌표
 * @param {number} y 위젯의 세로 시작 좌표
 * @param {number} w 위젯의 가로크기
 * @param {number} h 위젯의 세로크기
 * @param {number} r 위젯의 회전 각도
 * @param {number} scale 위젯의 스케일 비율
 * @param {string} uuid 위젯의 고유키
 * @param {object} getWidget 위젯의 정보를 가져옵니다.
 * @param {object} panelSize 패널의 크기 정보를 담고 있습니다.
 * @param {function} setWidget 위젯의 정보를 수정합니다.
 * @param {boolean} assist 어시스트 기능 활성화 여부
 * @param {function} modifyWidget 위젯을 수정하는 함수
 * @param {boolean} visible 해당 위젯의 영역이 보이도록 합니다.
 * @param {*} option 디스플레이에 적용할 옵션
 * @returns 
 */

const WidgetController = React.memo(function WidgetController({ x, y, w, h, r, scale, uuid, getWidget, panelSize, setWidget, assist, modifyWidget, visible, option = {} }) {
    //////////////////////////////////////////////////////////
    // State
    //////////////////////////////////////////////////////////

    // 컨텍스트 접근
    const context = useContext(EditContext);
    // 회전 제어를 위한 레퍼런스
    const RotateRef = useRef(null);
    
    // 상태 관리
    // ControllerMode: 현재 컨트롤러 모드 (기본: null, 이동: 'move' 등)
    const [ControllerMode, setControllerMode] = useState(null);

    // 위젯의 크기와 위치 상태
    const [Width, setWidth] = useState(w);
    const [Height, setHeight] = useState(h);
    const [Xpoint, setXpoint] = useState(x);
    const [Ypoint, setYpoint] = useState(y);
    const [Rotate, setRotate] = useState(Number(r));
    
    // 위젯의 기본 위치 (초기 드래그 시작점)
    const [Default, setDefault] = useState({ x: null, y: null });

    // 위젯의 이동 및 크기 변경에 따른 변위 상태
    const [Dispiacement, setDispiacement] = useState({ x: 0, y: 0, w: 0, h: 0 });
    // 리사이즈 핸들의 방향 상태
    const [ResizeDirection, setResizeDirection] = useState(null);
    // 위젯 각 모서리 좌표 상태 (디버그용)
    const [vertex, setVertex] = useState({
        LeftTop: [0, 0],
        CenterTop: [0, 0],
        RightTop: [0, 0],
        LeftCenter: [0, 0],
        RightCenter: [0, 0],
        LeftBottom: [0, 0],
        CenterBottom: [0, 0],
        RightBottom: [0, 0]
    });

    // 캐시 상태
    const [Cache, setCache] = useState(null);
    // const displayRef = useRef(null);

    //////////////////////////////////////////////////////////
    // Life Cycle
    //////////////////////////////////////////////////////////

    // 초기 마운트 시 실행되는 Effect
    useEffect(() => {
        resetVertex(); // 꼭짓점 초기화
        // getOverlapList() 
        // Cleanup function (optional)
        return () => {
            // 컴포넌트 언마운트 시 수행할 작업 (없음)
        };
    }, []);

    // x, y, w, h, r 변경 시 실행되는 Effect
    useEffect(() => {
        resetVertex(); // 꼭짓점 재설정
        setWidth(w); // 가로 크기 업데이트
        setHeight(h); // 세로 크기 업데이트
        setXpoint(x); // x 좌표 업데이트
        setYpoint(y); // y 좌표 업데이트
        setRotate(Number(r)); // 회전 각도 업데이트
    }, [x, y, w, h, r]);

    // 변위가 변경될 때마다 꼭짓점 재설정
    useEffect(() => {
        resetVertex();
    }, [Dispiacement]);

    // 캐시가 업데이트될 때 실행되는 Effect
    useEffect(() => {
        if (getWidget) {
            const ResponseCache = context.getChannelCache(getWidget);
            setCache(ResponseCache);
        }
    }, [context.channelCache]);

    //////////////////////////////////////////////////////////
    // Function
    //////////////////////////////////////////////////////////

    /**
     * 꼭짓점 좌표를 초기화하는 함수
     */
    const resetVertex = () => {
        // 현재 위젯 데이터로부터 직사각형 꼭짓점 계산
        const RectVertex = getRactVertex(getWidget.data);
        setVertex({
            LeftTop: RectVertex?.LeftTop,
            CenterTop: { x: (RectVertex?.RightTop?.x - RectVertex?.LeftTop?.x) / 2 + RectVertex?.LeftTop?.x, y: (RectVertex?.RightTop?.y - RectVertex?.LeftTop?.y) / 2 + RectVertex?.LeftTop?.y },
            RightTop: RectVertex?.RightTop,
            LeftCenter: { x: (RectVertex?.LeftBottom?.x - RectVertex?.LeftTop?.x) / 2 + RectVertex?.LeftTop?.x, y: (RectVertex?.LeftBottom?.y - RectVertex?.LeftTop?.y) / 2 + RectVertex?.LeftTop?.y },
            RightCenter: { x: (RectVertex?.RightBottom?.x - RectVertex?.RightTop?.x) / 2 + RectVertex?.RightTop?.x, y: (RectVertex?.RightBottom?.y - RectVertex?.RightTop?.y) / 2 + RectVertex?.RightTop?.y },
            LeftBottom: RectVertex?.LeftBottom,
            CenterBottom: { x: (RectVertex?.RightBottom?.x - RectVertex?.LeftBottom?.x) / 2 + RectVertex?.LeftBottom?.x, y: (RectVertex?.RightBottom?.y - RectVertex?.LeftBottom?.y) / 2 + RectVertex?.LeftBottom?.y },
            RightBottom: RectVertex?.RightBottom
        });
    }

    //////////////////////////////////////////////////////////
    // JSX
    //////////////////////////////////////////////////////////

    // 위젯의 스타일 정의
    const WidgetPosition = {
        position: 'absolute',
        height: Height + 'px',
        width: Width + 'px',
        top: Ypoint + 'px',
        left: Xpoint + 'px',
        // transform: rotate(45deg)
    }

    // 왼쪽 패널의 너비 계산
    const LeftPanel = panelSize?.PreferencesPanel?.width + 20 ?? 0

    // 가이드 색상 초기화
    let guideColor = 'rgba(0,0,0,0)';
    let guide = '';
    // 현재 컨텍스트의 액션에 따라 위젯 위치 및 가이드 색상 조정
    switch (context.action) {
        case 'move':
            WidgetPosition['top'] = Ypoint + (Dispiacement.y * scale) + 'px';
            WidgetPosition['left'] = Xpoint + LeftPanel + (Dispiacement.x * scale) + 'px';
            guideColor = 'rgba(255,0,0,0.2)'; // 이동 시 빨간색 가이드
            break;
        case 'resize':
            const widgetW = Number(getWidget?.data?.size?.width) ?? null
            const widgetH = Number(getWidget?.data?.size?.height) ?? null
            if (widgetH == null && widgetW == null) {
                return
            }
            WidgetPosition['top'] = Ypoint + (Dispiacement.y * scale) + 'px';
            WidgetPosition['left'] = Xpoint + LeftPanel + (Dispiacement.x * scale) + 'px';
            WidgetPosition['height'] = Height - (Dispiacement.h * scale) + 'px';
            WidgetPosition['width'] = Width - (Dispiacement.w * scale) + 'px';
            guideColor = 'rgba(0,255,255,0.2)'; // 리사이즈 시 청록색 가이드
            break;
        case 'rotate':
            WidgetPosition['top'] = Ypoint + (Dispiacement.y * scale) + 'px';
            WidgetPosition['left'] = Xpoint + LeftPanel + (Dispiacement.x * scale) + 'px';
            guideColor = 'rgba(247, 0, 255, 0.3)'; // 회전 시 보라색 가이드
            break;
        default:
            break;
    }

    // 컨트롤 확장 스타일 정의
    const ControlExpansion = {
        display: 'null',
        position: 'absolute',
        height: '100vh',
        width: '100vw',
        top: 0,
        left: -LeftPanel,
        zIndex: 2,
    }
    const ControlReduction = {
        // 축소된 상태의 스타일 (현재 비어 있음)
    }
    // visible 상태에 따라 배경색 적용
    if (visible) {
        ControlExpansion['background'] = guideColor
    }

    /** 
     * 핸들과 관련된 컴포넌트 내 state를 초기화하는 함수
     */
    const clearHandle = () => {
        context.setAction(null); // 현재 액션 초기화
        setDefault({ x: null, y: null }); // 기본 위치 초기화
        setDispiacement({ x: 0, y: 0, w: 0, h: 0 }); // 변위 초기화
    }

    /** 
     * [리스너] 위젯을 드래그 시작할 때의 동작을 정의합니다.
     * 
     * @param {*} e 마우스 이벤트 (onMouseDown)의 이벤트 객체
     */
    const MoveActionStart = (e) => {
        context.hideContext(); // 컨텍스트 창 숨김
        // 드래그 시작 시 마우스 위치 기록
        setDefault(prev => ({ ...prev, x: e.clientX, y: e.clientY, }));
        context.setAction('move'); // 액션을 'move'로 설정
        setControllerMode(null); // 다른 컨트롤 모드 비활성화
        e.stopPropagation(); // 이벤트 버블링 방지
    }

    /**
     * 드래그 중 발생하는 동작을 처리하는 함수
     * 
     * @param {*} e 마우스 이벤트 객체
     */
    const DragAction = (e) => {
        // 초기 드래그 위치가 설정되지 않은 경우 무시
        if (Default.x == null || Default.y == null) {
            return;
        }

        const OriginalScale = 1 / scale; // 편집 화면과 실제 크기의 비율
        const angleInDegrees = r || 0; // 회전 각도 (기본값: 0도)

        // 드래그 시작 위치에서의 변화량 계산
        const deltaX = (e.clientX - Default.x) * OriginalScale; // x 이동량
        const deltaY = (e.clientY - Default.y) * OriginalScale; // y 이동량
        
        // 현재 액션에 따라 처리
        switch (context.action) {
            case 'move': // 이동 드래그
                // 변위 상태 업데이트
                setDispiacement(prev => ({ ...prev, x: deltaX, y: deltaY }));
                break;

            case 'resize': // 리사이즈 드래그
                // 회전 각도에 따른 보정
                const angleInRadians = (angleInDegrees * Math.PI) / 180;
                const correctedX = deltaX * Math.cos(-angleInRadians) - deltaY * Math.sin(-angleInRadians);
                const correctedY = deltaX * Math.sin(-angleInRadians) + deltaY * Math.cos(-angleInRadians);
                // 리사이즈 드래그 처리
                resizeDrag(correctedX, correctedY);
                break;

            case 'rotate': // 회전 드래그
                rotateDrag(e.clientX, e.clientY);
                break;

            default:
                break;
        }
    };

    /** 
     * [리스너] 위젯을 드래그 종료할 때의 동작을 정의합니다.
     * @param {*} e 마우스 이벤트 (onMouseUp)의 이벤트 객체
     */
    const MouseUpAction = (e) => {
        switch (context.action) {
            case 'move':
                MoveActionEnd(e);
                break;
            case 'resize':
                ResizeActionEnd(e);
                break;
            case 'resizeDisable':
                ResizeActionEnd(e);
                break;
            case 'rotate':
                rotateActionEnd(e);
                break;
            default:
                break;
        }
    }

    /**
     * 인스턴트 컨트롤러 컴포넌트를 반환하는 함수
     */
    const getInstantControl = () => {
        const modifyCache = (RequestCache, target) => {
            context.setChannelCache(RequestCache, target);
        }
        const modifyWidget = (RequestData) => {
            // 위젯 수정 로직 (비어 있음)
        }

        return DeCoder.getControllerComponent(getWidget, modifyWidget, Cache, modifyCache);
    }

    /**
     * 이동 액션 종료 시 실행되는 함수
     * 
     * @param {*} e 마우스 이벤트 객체
     */
    const MoveActionEnd = (e) => {
        if (context.action == 'move') {
            const OriginalScale = 1 / scale;
            // 새로운 x, y 좌표 계산
            const modifyX = Math.round(Number(getWidget.data.position.x) + Number((e.clientX - Default.x) * OriginalScale)).toString();
            const modifyY = Math.round(Number(getWidget.data.position.y) + Number((e.clientY - Default.y) * OriginalScale)).toString();
            // 수정할 위젯 데이터 생성
            const ModifyWidget = { position: { x: modifyX, y: modifyY } }
            Widget.modify(context, uuid, ModifyWidget); // 위젯 수정 함수 호출
            clearHandle(); // 핸들 초기화
            e.stopPropagation(); // 이벤트 버블링 방지
        }
    }

    /** 
     * 위젯의 사이즈를 변경하는 함수. [ResizeActionStart, resizeDrag, ResizeActionEnd]와 함께 사용됩니다.
     * @param {*} e 마우스 이벤트 (onMouseDown)의 이벤트 객체
     * @param {string} position 리사이즈 방향 (예: 'LeftTop')
     */
    const ResizeActionStart = (e, position) => {
        context.hideContext(); // 컨텍스트 창 숨김
        context.setAction('resize'); // 액션을 'resize'로 설정
        setDefault({ x: e.clientX, y: e.clientY, }); // 드래그 시작 위치 기록
        setResizeDirection(position); // 리사이즈 방향 설정
        setControllerMode(null); // 다른 컨트롤 모드 비활성화
        e.stopPropagation(); // 이벤트 버블링 방지
    }

    /**
     * 리사이즈 드래그 시 호출되는 함수
     * 
     * @param {number} mouseX 마우스의 x 좌표 변화량
     * @param {number} mouseY 마우스의 y 좌표 변화량
     */
    const resizeDrag = (mouseX, mouseY) => {

        // 타입 변환
        mouseX = Number(mouseX)
        mouseY = Number(mouseY)
        const angle = Number(r)

        // 위젯의 현재 위치와 크기 정보 가져오기
        const widgetX = Number(getWidget.data?.position?.x)
        const widgetY = Number(getWidget.data?.position?.y)
        const widgetW = Number(getWidget.data?.size?.width)
        const widgetH = Number(getWidget.data?.size?.height)

        // 위젯에 필요한 속성이 없으면 함수 종료
        if (widgetX == null || widgetY == null || widgetW == null || widgetH == null) {
            return
        }

        let visibleX = 0
        let visibleY = 0
        let visibleW = 0
        let visibleH = 0

        // 리사이즈 방향에 따라 변위 계산
        switch (ResizeDirection) {
            case 'LeftTop':
                if (r == 0) {
                    visibleX = widgetX + (mouseX - widgetX);
                    visibleY = widgetY + (mouseY - widgetY);
                    visibleW = mouseX;
                    visibleH = mouseY;
                    setDispiacement({ x: Math.round(visibleX), y: Math.round(visibleY), w: Math.round(visibleW), h: Math.round(visibleH) });
                } else {
                    const PivotRect = resizeShapeWithPivot({ x: widgetX, y: widgetY, w: widgetW, h: widgetH, angle: angle }, 'tl', mouseX, mouseY)
                    setDispiacement({ x: Math.round(widgetX - PivotRect.x), y: Math.round(widgetY - PivotRect.y), w: Math.round((PivotRect.w - widgetW)), h: Math.round((PivotRect.h - widgetH)) });
                }
                break;
            case 'CenterTop':
                if (r == 0) {
                    visibleY = widgetY + (mouseY - widgetY);
                    visibleH = mouseY;
                    setDispiacement(prev => ({ ...prev, y: Math.round(visibleY), h: Math.round(visibleH) }));
                } else {
                    const PivotRect = resizeShapeWithPivot({ x: widgetX, y: widgetY, w: widgetW, h: widgetH, angle: angle }, 'tl', 0, mouseY)
                    setDispiacement({ x: Math.round(widgetX - PivotRect.x), y: Math.round(widgetY - PivotRect.y), w: Math.round((PivotRect.w - widgetW)), h: Math.round((PivotRect.h - widgetH)) });
                }
                break;
            case 'RightTop':
                if (r == 0) {
                    visibleX = 0
                    visibleY = widgetY + (mouseY - widgetY);
                    visibleW = -mouseX;
                    visibleH = mouseY;
                    setDispiacement({ x: Math.round(visibleX), y: Math.round(visibleY), w: Math.round(visibleW), h: Math.round(visibleH) });
                } else {
                    const PivotRect = resizeShapeWithPivot({ x: widgetX, y: widgetY, w: widgetW, h: widgetH, angle: angle }, 'tr', -mouseX, mouseY)
                    setDispiacement({ x: Math.round(widgetX - PivotRect.x), y: Math.round(widgetY - PivotRect.y), w: Math.round((PivotRect.w - widgetW)), h: Math.round((PivotRect.h - widgetH)) });
                }
                break;
            case 'LeftCenter':
                if (r == 0) {
                    visibleX = widgetX + (mouseX - widgetX);
                    visibleW = mouseX;
                    setDispiacement(prev => ({ ...prev, x: Math.round(visibleX), w: Math.round(visibleW) }));
                } else {
                    const PivotRect = resizeShapeWithPivot({ x: widgetX, y: widgetY, w: widgetW, h: widgetH, angle: angle }, 'tl', mouseX, 0)
                    setDispiacement({ x: Math.round(widgetX - PivotRect.x), y: Math.round(widgetY - PivotRect.y), w: Math.round((PivotRect.w - widgetW)), h: Math.round((PivotRect.h - widgetH)) });
                }
                break;
            case 'RightCenter':
                if (r == 0) {
                    visibleX = 0
                    visibleW = -mouseX;
                    setDispiacement(prev => ({ ...prev, x: Math.round(visibleX), w: Math.round(visibleW) }));
                } else {
                    const PivotRect = resizeShapeWithPivot({ x: widgetX, y: widgetY, w: widgetW, h: widgetH, angle: angle }, 'br', -mouseX, 0)
                    setDispiacement({ x: Math.round(widgetX - PivotRect.x), y: Math.round(widgetY - PivotRect.y), w: Math.round((PivotRect.w - widgetW)), h: Math.round((PivotRect.h - widgetH)) });
                }
                break;
            case 'LeftBottom':
                if (r == 0) {
                    visibleX = widgetX + (mouseX - widgetX);
                    visibleY = 0
                    visibleW = mouseX;
                    visibleH = -mouseY;
                    setDispiacement({ x: Math.round(visibleX), y: Math.round(visibleY), w: Math.round(visibleW), h: Math.round(visibleH) });
                } else {
                    const PivotRect = resizeShapeWithPivot({ x: widgetX, y: widgetY, w: widgetW, h: widgetH, angle: angle }, 'bl', mouseX, -mouseY)
                    setDispiacement({ x: Math.round(widgetX - PivotRect.x), y: Math.round(widgetY - PivotRect.y), w: Math.round((PivotRect.w - widgetW)), h: Math.round((PivotRect.h - widgetH)) });
                }
                break;
            case 'CenterBottom':
                if (r == 0) {
                    visibleY = 0
                    visibleH = -mouseY;
                    setDispiacement(prev => ({ ...prev, y: Math.round(visibleY), h: Math.round(visibleH) }));
                } else {
                    const PivotRect = resizeShapeWithPivot({ x: widgetX, y: widgetY, w: widgetW, h: widgetH, angle: angle }, 'br', 0, -mouseY)
                    setDispiacement({ x: Math.round(widgetX - PivotRect.x), y: Math.round(widgetY - PivotRect.y), w: Math.round((PivotRect.w - widgetW)), h: Math.round((PivotRect.h - widgetH)) });
                }
                break;
            case 'RightBottom':
                if (r == 0) {
                    visibleX = 0
                    visibleY = 0
                    visibleW = -mouseX;
                    visibleH = -mouseY;
                    setDispiacement({ x: Math.round(visibleX), y: Math.round(visibleY), w: Math.round(visibleW), h: Math.round(visibleH) });
                } else {
                    const PivotRect = resizeShapeWithPivot({ x: widgetX, y: widgetY, w: widgetW, h: widgetH, angle: angle }, 'br', -mouseX, -mouseY)
                    setDispiacement({ x: Math.round(widgetX - PivotRect.x), y: Math.round(widgetY - PivotRect.y), w: Math.round((PivotRect.w - widgetW)), h: Math.round((PivotRect.h - widgetH)) });
                }
                break;
            default:
                break;
        }
    }

    /**
     * 리사이즈 액션 종료 시 실행되는 함수
     * 
     * @param {*} e 마우스 이벤트 객체
     */
    const ResizeActionEnd = (e) => {
        if (context.action == 'resize' || context.action == 'resizeDisable') {
            const OriginalScale = 1 / scale;
            // 새로운 위치 및 크기 계산
            const modifyX = Math.round(Number(getWidget.data.position.x) + Number(Dispiacement.x)).toString();
            const modifyY = Math.round(Number(getWidget.data.position.y) + Number(Dispiacement.y)).toString();
            const modifyW = Math.round(Number(getWidget.data.size.width) - Number(Dispiacement.w)).toString();
            const modifyH = Math.round(Number(getWidget.data.size.height) - Number(Dispiacement.h)).toString();
            // 수정할 위젯 데이터 생성
            const ModifyWidget = { position: { x: modifyX, y: modifyY }, size: { width: modifyW, height: modifyH } }
            Widget.modify(context, uuid, ModifyWidget); // 위젯 수정 함수 호출
            clearHandle(); // 핸들 초기화
            e.stopPropagation(); // 이벤트 버블링 방지
        }
    }

    /**
     * 회전 액션 시작 시 실행되는 함수
     * 
     * @param {*} e 마우스 이벤트 객체
     * @param {string} position (사용되지 않음)
     */
    const rotateActionStart = (e, position) => {
        // 마우스 시작 위치 기록
        setDefault({ x: e.clientX, y: e.clientY, });
        context.hideContext(); // 컨텍스트 창 숨김
        context.setAction('rotate'); // 액션을 'rotate'로 설정
        e.stopPropagation(); // 이벤트 버블링 방지
    }

    /**
     * 회전 드래그 시 호출되는 함수
     * 
     * @param {number} mouseX 마우스의 x 좌표
     * @param {number} mousey 마우스의 y 좌표
     */
    const rotateDrag = (mouseX, mousey) => {
        if (RotateRef.current) {
            // 엘리먼트의 크기와 위치 정보 가져오기
            const rect = RotateRef.current.getBoundingClientRect();

            // 뷰포트 기준으로 중앙 좌표 계산
            const centerX = rect.left + rect.width / 2;
            const centerY = rect.top + rect.height / 2;
            const deltaX = mouseX - centerX;
            const deltaY = mousey - centerY;
            // 각도 계산 (라디안)
            const angleInRadians = Math.atan2(deltaY, deltaX);

            // 라디안을 도 단위로 변환 후 기준 조정
            let angle = Math.floor(angleInRadians * (180 / Math.PI)) + 90;

            // 0° ~ 359° 범위로 보정
            angle = ((angle % 360) + 360) % 360;

            setRotate(Math.floor(angle)); // 회전 각도 상태 업데이트
        }
    }

    /**
     * 회전 액션 종료 시 실행되는 함수
     * 
     * @param {*} e 마우스 이벤트 객체
     */
    const rotateActionEnd = (e, position) => {
        context.hideContext(); // 컨텍스트 창 숨김
        context.setAction(null); // 액션 초기화
        const ModifyWidget = { position: { rotate: Rotate } }
        Widget.modify(context, uuid, ModifyWidget); // 위젯 회전 각도 수정
        e.stopPropagation(); // 이벤트 버블링 방지
    }

    /**
     * 회전된 사각형의 경계를 계산하는 함수
     * 
     * @param {number} x 사각형의 x 좌표
     * @param {number} y 사각형의 y 좌표
     * @param {number} w 사각형의 너비
     * @param {number} h 사각형의 높이
     * @param {number} angleDeg 회전 각도 (도 단위)
     * @returns {object} 회전된 사각형의 새로운 좌표와 크기
     */
    const getRotatedRectBounds = (x, y, w, h, angleDeg) => {
        // 1) 사각형의 중심점 계산
        const cx = x + w / 2;
        const cy = y + h / 2;

        // 2) 각도를 라디안으로 변환
        const angleRad = (angleDeg * Math.PI) / 180;

        // 3) 회전 전의 4개 꼭짓점 정의
        const corners = [
            { px: x, py: y }, // 왼쪽 위
            { px: x + w, py: y }, // 오른쪽 위
            { px: x, py: y + h }, // 왼쪽 아래
            { px: x + w, py: y + h }, // 오른쪽 아래
        ];

        // 4) 각 꼭짓점을 회전
        const rotatedCorners = corners.map(({ px, py }) => {
            const nx = cx + (px - cx) * Math.cos(angleRad) - (py - cy) * Math.sin(angleRad);
            const ny = cy + (px - cx) * Math.sin(angleRad) + (py - cy) * Math.cos(angleRad);
            return { x: nx, y: ny };
        });

        // 5) 새로운 bounding box 계산
        const xs = rotatedCorners.map(c => c.x);
        const ys = rotatedCorners.map(c => c.y);

        const xMin = Math.min(...xs);
        const xMax = Math.max(...xs);
        const yMin = Math.min(...ys);
        const yMax = Math.max(...ys);

        // 6) 결과 반환 (왼쪽 위 좌표, 너비, 높이)
        return {
            x: xMin,
            y: yMin,
            w: xMax - xMin,
            h: yMax - yMin
        };
    }

    /**
     * 컨트롤 가이드를 렌더링하는 함수
     */
    const ControlGuide = () => {
        console.log('ControlGuide',context.action)
        switch (context.action) {
            case 'move':
                return <PositionGuide x={Math.floor(Number(getWidget.data.position.x) + Dispiacement.x)} y={Math.floor(Number(getWidget.data.position.y) + Dispiacement.y)} />
            case 'moveKeyAction':
                return <PositionGuide x={Math.floor(Number(getWidget.data.position.x) + Dispiacement.x)} y={Math.floor(Number(getWidget.data.position.y) + Dispiacement.y)} />
            case 'resize':
                return <ResizeGuide width={Number(getWidget.data.size.width) - Dispiacement.w} height={Number(getWidget.data.size.height) - Dispiacement.h} />
            case 'rotate':
                return <RotateGuide r={Rotate} />

            case 'resizeDisable':
                return (
                    <div id='WidgetPositionGuide' >
                        크기 조정이 불가능합니다.
                    </div>
                )
            default:
                return null
        }
    }

    /**
     * 위젯을 보이도록 설정하는 함수
     */
    const actionShow = () => {
        let targetWidget = getWidget;
        const visible = targetWidget.data?.display?.show;
        if (visible == false) {
            targetWidget['data']['display']['show'] = true;
            Widget.modify(context, uuid, targetWidget.data);
        }
        context.hideContext();
    }

    /**
     * 위젯을 숨기도록 설정하는 함수
     */
    const actionHide = () => {
        let targetWidget = getWidget;

        const visible = targetWidget.data?.display?.show;
        if (visible == true) {
            targetWidget['data']['display']['show'] = false;
            Widget.modify(context, uuid, targetWidget.data);
        }
        context.hideContext();
    }

    /**
     * 컨텍스트 메뉴를 표시하는 함수
     * 
     * @param {*} e 마우스 이벤트 객체
     */
    const ShowContext = (e) => {
        context.showContext(uuid, 'widget', e.clientX, e.clientY);
        e.stopPropagation(); // 이벤트 버블링 방지
        e.preventDefault(); // 기본 동작 방지
    }

    // 위젯의 가시성 상태
    const WidgetVisible = getWidget.data?.display?.show;

    // 현재 액션이 컨트롤 어시스트 모드에 해당하는지 확인
    const ControlAssist = ["move", "resize", "rotate"];

    // 회전 시 툴바의 위치 계산
    const Bounds = getRotatedRectBounds(Xpoint, Ypoint, Width, Height, Rotate).y - Ypoint;
    // 컨트롤 어시스트 모드에 따른 스타일 적용
    const PlaceStyle = ControlAssist.includes(context.action) ? ControlExpansion : ControlReduction
    
    const resizeCursor = getDirection8(r)

    return (
        <div className='WidgetControllerPlace' style={PlaceStyle}
            onMouseMove={(e) => DragAction(e)}
            onMouseUp={(e) => MouseUpAction(e)}
            onTouchMove={(e) => DragAction(e)}
            onTouchEnd={(e) => MouseUpAction(e)}
        >

            <div className='WidgetController' style={WidgetPosition}
                // onClick={(e)=>selectWidget(e)}
                onClick={(e) => e.stopPropagation()} // 클릭 시 이벤트 버블링 방지
                onContextMenu={(e) => ShowContext(e)} // 우클릭 시 컨텍스트 메뉴 표시
                onMouseDown={(e) => MoveActionStart(e)} // 마우스 다운 시 이동 액션 시작
                onMouseUp={(e) => MoveActionEnd(e)} // 마우스 업 시 이동 액션 종료
                onTouchStart={(e) => MoveActionStart(e)} // 터치 시작 시 이동 액션 시작
                onTouchEnd={(e) => MoveActionEnd(e)} // 터치 종료 시 이동 액션 종료
            >
                <style jsx>{`${WidgetSelecterStyle}`}</style>
                {ControllerMode == null? 
                <div className='InstantControllerArea'  style={{ transform: `rotate(${Rotate > 270 || Rotate < 90  ?Rotate:Rotate-180}deg)` }}>
                    {getInstantControl()} {/* 인스턴트 컨트롤러 렌더링 */}
                </div> :null}
                <div className='ControllerLine' style={{ transform: `rotate(${Rotate}deg)` }}>
                    {/* 각 리사이즈 포인트 정의 및 이벤트 핸들러 연결 */}
                    <span className='ResizePoint LeftTop'
                        onMouseDown={(e) => ResizeActionStart(e, 'LeftTop')}
                        onTouchStart={(e) => ResizeActionStart(e, 'LeftTop')}
                        style={{ transform: `rotate(${-r}deg)`,cursor : resizeCursor.LeftTop }}
                    >
                        <div className='vertexInfo'>{`${vertex?.LeftTop?.x ?? '-'},${vertex?.LeftTop?.y ?? '-'}`}</div>
                    </span>
                    <span className='ResizePoint CenterTop'
                        onMouseDown={(e) => ResizeActionStart(e, 'CenterTop')}
                        onTouchStart={(e) => ResizeActionStart(e, 'CenterTop')}
                        style={{ transform: `rotate(${-r}deg)`,cursor : resizeCursor.CenterTop }}
                    >
                        <div className='vertexInfo'>{`${vertex?.CenterTop?.x ?? '-'},${vertex?.CenterTop?.y ?? '-'}`}</div>
                    </span>
                    <span className='ResizePoint RightTop'
                        onMouseDown={(e) => ResizeActionStart(e, 'RightTop')}
                        onTouchStart={(e) => ResizeActionStart(e, 'RightTop')}
                        style={{ transform: `rotate(${-r}deg)`,cursor : resizeCursor.RightTop }}
                    >
                        <div className='vertexInfo'>{`${vertex?.RightTop?.x ?? '-'},${vertex?.RightTop?.y ?? '-'}`}</div>
                    </span>
                    <span className='ResizePoint LeftCenter'
                        onMouseDown={(e) => ResizeActionStart(e, 'LeftCenter')}
                        onTouchStart={(e) => ResizeActionStart(e, 'LeftCenter')}
                        style={{ transform: `rotate(${-r}deg)`,cursor : resizeCursor.LeftCenter }}
                    >
                        <div className='vertexInfo'>{`${vertex?.LeftCenter?.x ?? '-'},${vertex?.LeftCenter?.y ?? '-'}`}</div>
                    </span>
                    <span className='ResizePoint RightCenter'
                        onMouseDown={(e) => ResizeActionStart(e, 'RightCenter')}
                        onTouchStart={(e) => ResizeActionStart(e, 'RightCenter')}
                        style={{ transform: `rotate(${-r}deg)`,cursor : resizeCursor.RightCenter }}
                    >
                        <div className='vertexInfo'>{`${vertex?.RightCenter?.x ?? '-'},${vertex?.RightCenter?.y ?? '-'}`}</div>
                    </span>
                    <span className='ResizePoint LeftBottom'
                        onMouseDown={(e) => ResizeActionStart(e, 'LeftBottom')}
                        onTouchStart={(e) => ResizeActionStart(e, 'LeftBottom')}
                        style={{ transform: `rotate(${-r}deg)`,cursor : resizeCursor.LeftBottom }}
                    >
                        <div className='vertexInfo'>{`${vertex?.LeftBottom?.x ?? '-'},${vertex?.LeftBottom?.y ?? '-'}`}</div>
                    </span>
                    <span className='ResizePoint CenterBottom'
                        onMouseDown={(e) => ResizeActionStart(e, 'CenterBottom')}
                        onTouchStart={(e) => ResizeActionStart(e, 'CenterBottom')}
                        style={{ transform: `rotate(${-r}deg)`,cursor : resizeCursor.CenterBottom }}
                    >
                        <div className='vertexInfo'>{`${vertex?.CenterBottom?.x ?? '-'},${vertex?.CenterBottom?.y ?? '-'}`}</div>
                    </span>
                    <span className='ResizePoint RightBottom'
                        onMouseDown={(e) => ResizeActionStart(e, 'RightBottom')}
                        onTouchStart={(e) => ResizeActionStart(e, 'RightBottom')}
                        style={{ transform: `rotate(${-r}deg)`,cursor : resizeCursor.RightBottom }}
                    >
                        <div className='vertexInfo'>{`${vertex?.RightBottom?.x ?? '-'},${vertex?.RightBottom?.y ?? '-'}`}</div>
                    </span>
                    {/* 회전 컨트롤이 활성화된 경우 회전 컨트롤 렌더링 */}
                    {ControllerMode == 'rotate' ?
                        <div className='RotateControl'>
                            <div className='RotateContainer'
                                onMouseDown={(e) => rotateActionStart(e)}
                                // onClick={(e) => {}}
                                style={{
                                    height: (Math.min(Height, Width) * 0.8) + 'px',
                                    width: (Math.min(Height, Width) * 0.8) + 'px',
                                    transform: `rotate(${-Rotate}deg)`
                                }}>
                                <div className='cycleContainer'>
                                    <svg className="rotation-handle" viewBox="0 0 100 100" style={{ transform: `rotate(${Rotate}deg)` }} ref={RotateRef}>
                                        <circle cx="50" cy="50" r="40"
                                            style={{ clipPath: `polygon(0% 0%, 25% 0%, 50% 50%, 75% 0%, 100% 0%, 100% 100%, 0% 100%)` }}
                                            stroke="#000000AA" strokeWidth="10" fill="none" />
                                        <circle cx="50" cy="50" r="40"
                                            style={{ clipPath: `polygon(25% 0%, 50% 50%, 75% 0%)` }}
                                            stroke="#FFFFFF33" strokeWidth="10" fill="none" />
                                    </svg>
                                    <div className='cycleInfo' style={{ transform: `scale(${Math.min(Height, Width) < 200 ? Math.min(Height, Width) / 100 * 0.6 : 1})` }}>
                                        {Rotate}°
                                    </div>
                                </div>
                            </div>
                        </div> : null}
                </div>

                <div className='editTool'
                    style={{ top: Bounds - 40, pointerEvents: (context.action ? 'none' : 'auto') }}
                    onMouseMove={(e) => e.stopPropagation()} // 이벤트 버블링 방지
                    onMouseUp={(e) => e.stopPropagation()}
                    onMouseDown={(e) => e.stopPropagation()}
                    onTouchMove={(e) => e.stopPropagation()}
                    onTouchEnd={(e) => e.stopPropagation()}
                    onTouchStart={(e) => e.stopPropagation()}
                >
                    <div className={context.action ? 'editOptions OptionsDisable' : 'editOptions OptionsActive'}>
                        {/* 위젯 복사 옵션 */}
                        <WidgetOption action={() => { Widget.copy(context, uuid); context.hideContext() }} icon={'content_copy'} info={'위젯을 복사합니다'} />
                        {/* 위젯 활성화 토글 옵션 */}
                        {WidgetVisible ?
                            <WidgetOption action={() => { actionHide(); context.hideContext() }} icon={'visibility_off'} info={'위젯을 보이지 않게 합니다.'} /> :
                            <WidgetOption action={() => { actionShow(); context.hideContext() }} icon={'visibility_off'} active={true} info={'위젯을 보이게 합니다.'} />}
                        {/* 
                        // 위젯의 z-index 조정 옵션 (주석 처리됨)
                        <WidgetOption action={()=>{Widget.indexEnd(context,uuid); context.hideContext()}} icon={'flip_to_front'} info={'위젯을 위로 올립니다'}/>
                        <WidgetOption action={()=>{Widget.indexFront(context,uuid); context.hideContext()}} icon={'flip_to_back'} info={'위젯을 아래로 내립니다'}/>
                        */}
                        {/* 위젯 회전 토글 옵션 */}
                        {ControllerMode == 'rotate'? 
                          <WidgetOption action={() => {setControllerMode(null); context.hideContext()}} active={true} icon={'sync'} info={'도형을 회전을 중지 시킵니다'} />:
                          <WidgetOption action={() => {setControllerMode('rotate'); context.hideContext()}}  icon={'sync'} info={'도형을 회전시킵니다'} />}
                        {/* 위젯 삭제 옵션 */}
                        <WidgetOption action={() => { Widget.del(context); context.hideContext() }} icon={'delete'} info={'위젯을 삭제합니다.'} />
                        {/* 상세 메뉴 표시 옵션 */}
                        <WidgetOption action={() => context.showContext(uuid, 'widget', Xpoint + 360 + Width + 20, Ypoint)} icon={'arrow_drop_down'} info={'상세 메뉴를 표시합니다'} />
                    </div>
                    <div className={context.action ? 'ControllerGuide GuideActive' : 'ControllerGuide GuideDisable'}>
                        {ControlGuide()} {/* 현재 액션에 따른 가이드 렌더링 */}
                    </div>

                  
                </div>
            </div>
        </div>
    )

    // height: Height+'px',
    // width : Width+'px',
    // top : Ypoint+'px',
    // left : Xpoint+'px'

});

export default WidgetController;
////////////////////////////////////////////////////////
// css 부분
//////////////////////////////////////////////////////////
const WidgetSelecterStyle = `

    .WidgetControllerPlace {
        position: absolute;
    }

    .WidgetController{
        cursor: move; /* 마우스 커서를 이동 커서로 설정 */
        box-sizing: border-box;
        position: relative;
    }
    .WidgetController .ControllerLine{
        position: absolute;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        border: 2px solid #a9a9a9; /* 회색 테두리 */
        z-index: 3;
        pointer-events: none;
    }
    
    .WidgetController .ControllerLine *{
        pointer-events: auto;
    }
    .WidgetController p{
        color : black;
    }

    .WidgetController .ResizePoint{
        display: flex;
        height: 10px;
        width: 10px;
        background: white;
        box-shadow: 1px 1px 3px #252525, -1px -1px 3px #636363; /* 그림자 효과 */
        border-radius: 999px; /* 원형 */
        z-index: 3;
        color: #000;
        position: absolute;
    }

    .WidgetController .RotateControl{
        display: flex;
        max-width: 100%;
        max-height: 100%;
        z-index: 3;
        color: #000;
        position: absolute;
        align-items: center;
        justify-content: center;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
    }

    .WidgetController .RotateControl .RotateContainer{
        max-width: 200px;
        max-height: 200px;
        display: flex;
        position: relative;
        align-items: center;
        justify-content: center;
    }
    .WidgetController .RotateControl .RotateContainer .cycleContainer{
        border-radius: 999px;
        width: 100%;
        height: 100%;
        z-index: 5;
    }
    .WidgetController .RotateControl .RotateContainer .cycleContainer .cycleInfo{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 30px;
        color : #ffffff;
        text-shadow: 0 2px 5px rgba(0, 0, 0, .15);
    }

    .WidgetController .InstantControllerArea{
        position: absolute;
        background-color: transparent;
        display: flex;
        height: 100%;
        width: 100%;
    }

    .WidgetController .editTool{
        top: -40px;
        left: 50%;
        transform: translateX(-50%);
        transition: top 0.1s;
        background-color: #3F3F3F; /* 어두운 회색 배경 */
        box-shadow: 0 2px 5px rgba(0,0,0,.15); /* 그림자 효과 */
        position: relative;
        height: 30px;
        border-radius: 5px;
        display: flex;
        align-items: flex-start;
        width: 200px;
        z-index: 20;
    }

    .WidgetController .editTool .ControllerGuide{
        background-color: orange;
        display: flex;
        transition: transform 0.3s;
        border-radius: 5px;
        justify-content: center;
        align-items: center;
        transform-origin: center top; 
        position: absolute;
        height: 30px;
        width: 200px;
        z-index : 2;
    }

    .WidgetController .editTool .GuideDisable{
        transform: rotateX(90deg); /* 가이드 숨김 */
        pointer-events: none;
    }

    .WidgetController .editTool .GuideActive{
        transform: rotateX(0deg); /* 가이드 표시 */
    }

    .WidgetController .editOptions{
        background-color: #3F3F3F; /* 어두운 회색 배경 */
        border-radius: 5px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center; 
        position: absolute;
        transition: transform 0.3s;
        transform-origin: center bottom; 
        width: 200px;
        z-index : 1;
    }

    .WidgetController .editOptions .OptionsDisable{
        transform: rotateX(-90deg); /* 옵션 숨김 */
    }

    .WidgetController .editOptions .OptionsActive{
        transform: rotateX(0deg); /* 옵션 표시 */
    }

    .WidgetController .editOptions button{
        height: 30px;
        min-width: 30px;
    }
    .WidgetController .editOptions button:hover{
        height: 30px;
        min-width: 30px;
        border-radius: 5px;
        background-color: #2C2C2C; /* 호버 시 배경색 변경 */
    }

    /* 각 리사이즈 포인트의 커서 스타일 및 위치 설정 */
    .WidgetController .LeftTop{
        cursor: nwse-resize; /* 대각선 리사이즈 커서 */
        top: -5px;
        left: -5px;
    }

    .WidgetController .CenterTop{
        cursor: ns-resize; /* 세로 리사이즈 커서 */
        top: -5px;
        left: 50%;
        transform: translateX(-50%);
    }

    .WidgetController .RightTop{
        cursor: nesw-resize; /* 대각선 리사이즈 커서 */
        top: -5px;
        right: -5px;
    }

    .WidgetController .LeftCenter{
        cursor: ew-resize; /* 가로 리사이즈 커서 */
        top: 50%;
        left: -5px;
        transform: translateY(-50%);
    }

    .WidgetController .RightCenter{
        cursor: ew-resize; /* 가로 리사이즈 커서 */
        top: 50%;
        right: -5px;
        transform: translateY(-50%);
    }

    .WidgetController .LeftBottom{
        cursor: nesw-resize; /* 대각선 리사이즈 커서 */
        bottom: -5px;
        left: -5px;
    }

    .WidgetController .CenterBottom{
        cursor: ns-resize; /* 세로 리사이즈 커서 */
        bottom: -5px;
        left: 50%;
        transform: translateX(-50%);
    }

    .WidgetController .RightBottom{
        cursor: nwse-resize; /* 대각선 리사이즈 커서 */
        bottom: -5px;
        right: -5px;
    }

    .actionGuide{
        pointer-events: none;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .vertexInfo{
        background-color: #2C2C2C; /* 어두운 배경 */
        border-radius: 5px;
        padding: 10px; 
        color: #fff;
        height: 30px;
        position: absolute;
        left: 20px; 
        z-index: 1;
        display: none; /* 기본적으로 숨김 */
    }

`;



/**
 * 
 * @param {*} WidgetData 위젯 데이터 객체
 * @returns 위젯의 각 꼭짓점 좌표를 반환하거나, 데이터가 없으면 false
 */
function getRactVertex(WidgetData) {

    // 1) 위젯의 위치와 크기 가져오기
    const WidgetX_OriginalScale = Number(WidgetData?.position?.x) ?? null
    const WidgetY_OriginalScale = Number(WidgetData?.position?.y) ?? null
    const WidgetW_OriginalScale = Number(WidgetData?.size?.width) ?? null
    const WidgetH_OriginalScale = Number(WidgetData?.size?.height) ?? null
    const WidgetR = Number(WidgetData?.position?.rotate) ?? 0

    // 2) 필요한 데이터가 없으면 false 반환
    if (WidgetX_OriginalScale == null || WidgetY_OriginalScale == null || WidgetW_OriginalScale == null || WidgetH_OriginalScale == null) {
        return false
    }

    // 3) 회전 전 꼭짓점 좌표 정의
    const OriginalVertex = {
        LeftTop: { x: WidgetX_OriginalScale, y: WidgetY_OriginalScale },
        RightTop: { x: WidgetX_OriginalScale + WidgetW_OriginalScale, y: WidgetY_OriginalScale },
        LeftBottom: { x: WidgetX_OriginalScale, y: WidgetY_OriginalScale + WidgetH_OriginalScale },
        RightBottom: { x: WidgetX_OriginalScale + WidgetW_OriginalScale, y: WidgetY_OriginalScale + WidgetH_OriginalScale }
    };

    // 4) 회전 각도가 0도면 회전 없이 원래 좌표 반환
    if (WidgetR == 0) {
        return OriginalVertex;
    }

    // 5) 도형의 중심점 계산
    const WidgetCenter = { x: WidgetW_OriginalScale / 2 + WidgetX_OriginalScale, y: WidgetH_OriginalScale / 2 + WidgetY_OriginalScale, };

    // 6) 꼭짓점 회전 후 좌표 계산
    const RotateVertex = {
        LeftTop: rotateVertex(WidgetX_OriginalScale, WidgetY_OriginalScale, WidgetR, WidgetCenter.x, WidgetCenter.y),
        RightTop: rotateVertex(WidgetX_OriginalScale + WidgetW_OriginalScale, WidgetY_OriginalScale, WidgetR, WidgetCenter.x, WidgetCenter.y),
        LeftBottom: rotateVertex(WidgetX_OriginalScale, WidgetY_OriginalScale + WidgetH_OriginalScale, WidgetR, WidgetCenter.x, WidgetCenter.y),
        RightBottom: rotateVertex(WidgetX_OriginalScale + WidgetW_OriginalScale, WidgetY_OriginalScale + WidgetH_OriginalScale, WidgetR, WidgetCenter.x, WidgetCenter.y),
    };

    return RotateVertex;
}

/**
 * 도형의 꼭짓점을 회전시키는 함수
 * 
 * @param {number} x 꼭짓점의 x 좌표
 * @param {number} y 꼭짓점의 y 좌표
 * @param {number} angle 회전 각도 (도 단위)
 * @param {number} centerX 도형의 중심 x 좌표
 * @param {number} centerY 도형의 중심 y 좌표
 * @returns {object} 회전된 꼭짓점의 새로운 x, y 좌표
 */
function rotateVertex(x, y, angle, centerX, centerY) {
    // 각도를 라디안으로 변환
    const angleInRadians = (angle * Math.PI) / 180;

    // 1. 중심점 기준으로 이동
    const translatedX = x - centerX;
    const translatedY = y - centerY;

    // 2. 회전 변환 적용
    const rotatedX =
        translatedX * Math.cos(angleInRadians) - translatedY * Math.sin(angleInRadians);
    const rotatedY =
        translatedX * Math.sin(angleInRadians) + translatedY * Math.cos(angleInRadians);

    // 3. 다시 중심점으로 이동
    const finalX = rotatedX + centerX;
    const finalY = rotatedY + centerY;

    return { x: Math.round(finalX), y: Math.round(finalY) };
}


/**
 * 도형을 특정 코너를 피벗으로 하여 리사이즈하는 함수
 * 
 * @param {object} rect - 도형의 현재 상태 {x, y, w, h, angle}
 * @param {string} pivotCorner - 피벗 코너 ('tl', 'tr', 'bl', 'br')
 * @param {number} deltaW - 변경할 너비
 * @param {number} deltaH - 변경할 높이
 * @returns {object} 변경된 도형의 새로운 상태 {x, y, w, h, angle}
 */
function resizeShapeWithPivot(rect, pivotCorner, deltaW, deltaH) {

    /**
     * 좌상단 기준을 중심으로 변경
     * 
     * @param {object} rect 도형의 현재 상태
     * @returns {object} 중심 기준으로 변환된 도형 상태
     */
    function topLeftToCenterRect(rect) {
        return {
            cx: rect.x + rect.w / 2,
            cy: rect.y + rect.h / 2,
            w: rect.w,
            h: rect.h,
            angle: rect.angle
        };
    }

    /**
     * 중심 기준을 좌상단 기준으로 변경
     * 
     * @param {object} rect 도형의 중심 기준 상태
     * @returns {object} 좌상단 기준으로 변환된 도형 상태
     */
    function centerToTopLeftRect(rect) {
        return {
            x: rect.cx - rect.w / 2,
            y: rect.cy - rect.h / 2,
            w: rect.w,
            h: rect.h,
            angle: rect.angle
        };
    }

    // 도형을 중심 기준으로 변환
    const shape = topLeftToCenterRect(rect)

    const { cx, cy, w, h, angle } = shape;

    // 회전각 (라디안)
    const rad = (angle * Math.PI) / 180;
    const cos = Math.cos(rad);
    const sin = Math.sin(rad);

    // 1) 현재 '피벗 코너'의 로컬 좌표 (도형 중심을 원점으로 한 로컬)
    let pivLocalX = 0, pivLocalY = 0;
    switch (pivotCorner) {
        case 'br': // bottom-right
            pivLocalX = -w / 2;
            pivLocalY = -h / 2;
            break;
        case 'bl': // bottom-left
            pivLocalX = +w / 2;
            pivLocalY = -h / 2;
            break;
        case 'tr': // top-right
            pivLocalX = -w / 2;
            pivLocalY = +h / 2;
            break;
        case 'tl': // top-left
            pivLocalX = +w / 2;
            pivLocalY = +h / 2;
            break;
        default:
            throw new Error('Unsupported pivotCorner: ' + pivotCorner);
    }

    // 2) 피벗 코너의 월드 좌표 계산 (변경되지 않아야 함)
    const pivotWorldX = cx + (pivLocalX * cos) - (pivLocalY * sin);
    const pivotWorldY = cy + (pivLocalX * sin) + (pivLocalY * cos);

    // 3) 새 너비와 높이 계산
    const newW = w + deltaW;
    const newH = h + deltaH;

    // 4) 같은 코너의 새로운 로컬 좌표 계산
    let newPivLocalX = 0, newPivLocalY = 0;
    switch (pivotCorner) {
        case 'br':
            newPivLocalX = -newW / 2;
            newPivLocalY = -newH / 2;
            break;
        case 'bl':
            newPivLocalX = +newW / 2;
            newPivLocalY = -newH / 2;
            break;
        case 'tr':
            newPivLocalX = -newW / 2;
            newPivLocalY = +newH / 2;
            break;
        case 'tl':
            newPivLocalX = +newW / 2;
            newPivLocalY = +newH / 2;
            break;
    }

    // 5) 피벗 월드 좌표를 유지하도록 새로운 중심점 계산
    const newCx =
        pivotWorldX - (newPivLocalX * cos - newPivLocalY * sin);
    const newCy =
        pivotWorldY - (newPivLocalX * sin + newPivLocalY * cos);

    // 6) 회전각은 그대로 유지
    const PivotRect = centerToTopLeftRect({
        cx: newCx,
        cy: newCy,
        w: newW,
        h: newH,
        angle
    });

    return PivotRect;
}


function getDirection8(angle) {
    // 입력 각도를 -180 ~ 180 범위로 정규화
    angle = ((angle + 180) % 360) - 180;
    
    // 각 영역의 경계 (단위: 도)
    // (각 영역의 중앙값과 ±22.5° 범위)
    if (angle >= -22.5 && angle < 22.5) {
        return {
        LeftTop:      'nwse-resize',
        CenterTop:    'ns-resize',
        RightTop:     'nesw-resize',
        RightCenter:  'ew-resize',
        RightBottom:  'nwse-resize',
        CenterBottom: 'ns-resize',
        LeftBottom:   'nesw-resize',
        LeftCenter:   'ew-resize'
          };   // 위쪽 중앙
    } else if (angle >= 22.5 && angle < 67.5) {
        return {
        LeftTop:      'ns-resize',
        CenterTop:    'nesw-resize',
        RightTop:     'ew-resize',
        RightCenter:  'nwse-resize',
        RightBottom:  'ns-resize',
        CenterBottom: 'nesw-resize',
        LeftBottom:   'ew-resize',
        LeftCenter:   'nwse-resize'
        };     // 오른쪽 위
    } else if (angle >= 67.5 && angle < 112.5) {
        return {
        LeftTop:      'nesw-resize',
        CenterTop:    'ew-resize',
        RightTop:     'nwse-resize',
        RightCenter:  'ns-resize',
        RightBottom:  'nesw-resize',
        CenterBottom: 'ew-resize',
        LeftBottom:   'nwse-resize',
        LeftCenter:   'ns-resize'
      };  // 오른쪽 중앙
    } else if (angle >= 112.5 && angle < 157.5) {
        return {
        LeftTop:      'ew-resize',
        CenterTop:    'nwse-resize',
        RightTop:     'ns-resize',
        RightCenter:  'nesw-resize',
        RightBottom:  'ew-resize',
        CenterBottom: 'nwse-resize',
        LeftBottom:   'ns-resize',
        LeftCenter:   'nesw-resize'
      };  // 오른쪽 아래
    } else if (angle >= 157.5 || angle < -157.5) {
        return {
        LeftTop:      'nwse-resize',
        CenterTop:    'ns-resize',
        RightTop:     'nesw-resize',
        RightCenter:  'ew-resize',
        RightBottom:  'nwse-resize',
        CenterBottom: 'ns-resize',
        LeftBottom:   'nesw-resize',
        LeftCenter:   'ew-resize'
      }; // 아래쪽 중앙
    } else if (angle >= -157.5 && angle < -112.5) {
        return {
        LeftTop:      'ns-resize',
        CenterTop:    'nesw-resize',
        RightTop:     'ew-resize',
        RightCenter:  'nwse-resize',
        RightBottom:  'ns-resize',
        CenterBottom: 'nesw-resize',
        LeftBottom:   'ew-resize',
        LeftCenter:   'nwse-resize'
      };   // 왼쪽 아래
    } else if (angle >= -112.5 && angle < -67.5) {
        return {
        LeftTop:      'nesw-resize',
        CenterTop:    'ew-resize',
        RightTop:     'nwse-resize',
        RightCenter:  'ns-resize',
        RightBottom:  'nesw-resize',
        CenterBottom: 'ew-resize',
        LeftBottom:   'nwse-resize',
        LeftCenter:   'ns-resize'
      };   // 왼쪽 중앙
    } else if (angle >= -67.5 && angle < -22.5) {
        return {
        LeftTop:      'ew-resize',
        CenterTop:    'nwse-resize',
        RightTop:     'ns-resize',
        RightCenter:  'nesw-resize',
        RightBottom:  'ew-resize',
        CenterBottom: 'nwse-resize',
        LeftBottom:   'ns-resize',
        LeftCenter:   'nesw-resize'
      };      // 왼쪽 위
    }
  }
  
