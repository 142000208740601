////////////////////////////////////////////////////////
// 오류처리&테스트를 위한 위젯
// 컴포넌트
import ExWidget from 'components/widget/ExWidget'
import Undefined from 'components/widget/Undefined'

////////////////////////////////////////////////////////


// 서비스 연결
import * as widgetModel from 'service/model/widgetModel'
import * as Utility from 'service/other/Utility'; 

// 공용
import SizeEditForm from 'components/Edit/editorField/SizeEditForm';
import PositionEditForm from 'components/Edit/editorField/PositionEditForm';
import FilterEditForm from 'components/Edit/editorField/FilterEditForm'; // 필터
import BrightnessEditForm from 'components/Edit/editorField/BrightnessEditForm'; // 필터
import OpacityEditForm from 'components/Edit/editorField/OpacityEditForm'; // 필터
import ColorEditForm from 'components/Edit/editorField/ColorEditForm'; // 색상
import ColorEditFormV2 from 'components/Edit/editorField/ColorEditFormV2'; // 색상 신버전
import RotateEditForm from 'components/Edit/editorField/RotateEditForm' // 회전

import TextureEditForm from 'components/Edit/editorField/TextureEditForm' //질감

import ShadowEditForm from 'components/Edit/editorField/ShadowEditForm'; // 그림자
import RadiusEditForm from 'components/Edit/editorField/RadiusEditForm'; // 그림자

import LegacyTextEditForm from 'components/Edit/editorField/LegacyTextEditForm'; // 텍스트 v1 에디터
import TextEditV2Form from 'components/Edit/editorField/TextEditV2Form';
// import TextEditV2Form from 'components/edit/editorField/TextEditV2Form';

import NoticeEditForm from 'components/Edit/editorField/NoticeEditForm'; // 메모 v1 에디터

import ChatV1Edit from 'components/Edit/editorField/ChatV1EditForm'; // 메모 v1 에디터
import NoticeStyleEditForm from 'components/Edit/editorField/NoticeStyleEditForm'; // 메모 v1 에디터
import ImageEditForm from 'components/Edit/editorField/ImageEditForm'; // 이미지 에디터
import VideoEditForm from 'components/Edit/editorField/VideoEditForm'; // 비디오 에디터
import CameraEditForm from 'components/Edit/editorField/CameraEditForm'; // 비디오 에디터
import TimerEditForm from 'components/Edit/editorField/TimerEditForm'; // 타이머 에디터
import StopWatchEditForm from 'components/Edit/editorField/StopWatchEditForm'; // 타이머 에디터
import ScrollCaptionEditForm from 'components/Edit/editorField/ScrollCaptionEditForm'; // 스크롤 에디터
import BG_GradientEditForm from 'components/Edit/editorField/BG_GradientEditForm'; // 백그라운드 에디터 | 그라디언트




import AnimationStartEditForm from 'components/Edit/editorField/AnimationStartEditForm' // 등장 애니메이션
import AnimationEndEditForm from 'components/Edit/editorField/AnimationEndEditForm' // 퇴장 애니메이션 
import MorphingEditForm from 'components/Edit/editorField/MorphingEditForm' // 모핑

import EditorInfo from 'components/Edit/editorField/EditorInfo'; // 정보



import PresetEditForm from 'components/Edit/editorField/PresetEditForm'; // 프리셋 에디터
import AssetEditForm from 'components/Edit/editorField/AssetEditForm'; // 에셋 에디터

import ScoreboardEditForm from 'components/Edit/editorField/ScoreboardEditForm'; // 스코어 에디터
import BanPickEditForm from 'components/Edit/editorField/BanPickEditForm'; // 밴픽 에디터
import BanPickRuleEditForm from 'components/Edit/editorField/BanPickRuleEditForm'; // 밴픽룰 에디터
import TeamEditForm from 'components/Edit/editorField/TeamEditForm'; // 스코어 에디터




////////////////////////////////////////////////////////
// 유틸리티
////////////////////////////////////////////////////////

export const LinkKey = (keyName,target) => {


  return target.data?.[keyName.category]?.[keyName.key]

  // keyName.category.position
  // target.data.[] [] 

}

/**
 * 오버랩 객체의 데이터 값을 가져옵니다.
 * @param {*} target 오버랩 객체, Data 아님
 * @param {*} keyName 찾으려는 키정보 {key,category} 
 * @returns 
 */
export const getValue = (target,keyName) => {

  return Utility.deepCopy(target.data?.[keyName.category]?.[keyName.key])
}



/**
 * 선택된 채널에서 편집중인 페이지를 송출중인지 확인합니다.
 * @param {*} target 오버랩 객체, Data 아님
 * @param {*} keyName 찾으려는 키정보 {key,category} 
 * @returns 
 */
export const CheckBroadcast = (context,flag) => {

  const Channel = context.view

  if (Channel==null){
    return false
  }

  // Channel.view_pointer
  const PageID = context.selectPageUUID
  
  const IsBroadcast = Channel.view_pointer.some(pointer => pointer.uuid == PageID);

  return (IsBroadcast)

}

////////////////////////////////////////////////////////
// 유틸리티
////////////////////////////////////////////////////////




const DefaultPosition = {form:PositionEditForm,keyName:{positionX : {'key' : 'x', 'category':'position'},positionY : {'key' : 'y', 'category':'position'},width : {'key' : 'width', 'category':'size'},height: {'key' : 'height', 'category':'size'}},title:{editTitle:null,textValue:null},option:{openForm:false,closeEdit:true}};
const DefaultRotate = {form:RotateEditForm,keyName:{rotate:{'key' : 'rotate', 'category':'position'}},title:{editTitle:null},option:{openForm:false,closeEdit:true}};
const DefaultSize = {form:SizeEditForm,keyName:{width : {'key' : 'width', 'category':'size'},height: {'key' : 'height', 'category':'size'},fixRatio: {'key' : 'fixRatio', 'category':'size'}},title:{editTitle:null},option:{openForm:false,closeEdit:true}};
const DefaultFilter = {form:FilterEditForm,keyName:{blur:{'key' : 'blur', 'category':'style'},grayscale:{'key' : 'grayscale', 'category':'style'},sepia:{'key' : 'sepia', 'category':'style'},invert:{'key' : 'invert', 'category':'style'}},title:{editTitle:null},option:{openForm:false}};
const DefaultBrightness = {form:BrightnessEditForm,keyName:{brightness:{'key' : 'brightness', 'category':'style'},contrast:{'key' : 'contrast', 'category':'style'}},title:{editTitle:null},option:{openForm:false}};
const DefaultOpacity = {form:OpacityEditForm, keyName:{opacity:{'key' : 'opacity', 'category':'style'}},title:{editTitle:null,textValue:null},option:{openForm:false,closeEdit:true}};
const DefaultShadow = {form:ShadowEditForm,keyName:{color:{'key' : 'shadowColor', 'category':'style'},direction:{'key' : 'shadowDirection', 'category':'style'},shadow:{'key' : 'shadowValue', 'category':'style'}},title:{editTitle:null},option:{openForm:false,closeEdit:true}};
const DefaultRadius = {form:RadiusEditForm,keyName:{borderRadius:{'key' : 'borderRadius', 'category':'style'}},title:{editTitle:null},option:{openForm:false,closeEdit:true}};
const DefaultColor = {form:ColorEditFormV2,keyName:{fill:{'key' : 'fill', 'category':'style'}},title:{editTitle:null},option:{openForm:false,closeEdit:true}};


const DefaultPreset = {form:PresetEditForm,keyName:{},title:{editTitle:null},option:{openForm:false}};

const DefaultAsset = {form:AssetEditForm,keyName:{},title:{editTitle:null},option:{openForm:false}};

const DefaultAnimation = [
                          {form:AnimationStartEditForm,keyName:{mountStart:{'key' : 'mountStart', 'category':'animation'}},title:{editTitle:null},option:{openForm:false}},
                          // {form:AnimationEndEditForm,keyName:{morphing:{'key' : 'morphing', 'category':'style'}},title:{editTitle:null},option:{openForm:false}},      
                          ]

const DefaultMorphing = {form:MorphingEditForm,keyName:{morphing:{'key' : 'morphing', 'category':'animation'}},title:{editTitle:null},option:{openForm:false}};




export const ErrorNoti = () =>{
  // 이곳이 에러라면 Default 에디터 설정에 문제가 있습니다.
}


export const UnKnownV1 = (preset,option) =>{

  const editor = {}

  switch (preset) {  
    default:
      editor['edit'] = [
        DefaultPosition,
        DefaultSize,
        {form:EditorInfo,keyName:{info:'해당 버전과 호환되지 않는 위젯입니다.'},title:{editTitle:null},option:{openForm:false}},
      ]
  }
  return editor[option]??[]
}

export const textV1 = (preset,option) =>{

  const editor = {}

  switch (preset) {  
    default:
      editor['edit'] = [
        DefaultPosition,
        DefaultSize,
        {form:LegacyTextEditForm,keyName:{text : {'key' : 'text', 'category':'value'}, fontSize: {'key' : 'fontSize', 'category':'style'}, fontFamily: {'key' : 'fontFamily', 'category':'style'}, textAlign: {'key' : 'textAlign', 'category':'style'}, useFill: {'key' : 'useFill', 'category':'style'}, fontColor: {'key' : 'color', 'category':'style'}, outlineColor : {'key' : 'outlineColor', 'category':'style'}, textOutline : {'key' : 'textOutline', 'category':'style'} },title:{editTitle:null,textValue:null},option:{fixToggle:true}},
        DefaultShadow,
        DefaultOpacity,
        DefaultRotate,
        // DefaultFilter
      ]
      editor['animation'] = [
        ...DefaultAnimation,
        DefaultMorphing,
      ]
  }
  return editor[option]??[]
}


export const textV2 = (preset,option) =>{

  const editor = {}

  switch (preset) {  
    default:
      editor['edit'] = [
        DefaultPosition,
        DefaultSize,
        {form:TextEditV2Form,keyName:{text : {'key' : 'text', 'category':'value'}, textAlign: {'key' : 'textAlign', 'category':'style'} , lineHeight: {'key' : 'lineHeight', 'category':'style'} },title:{editTitle:null,textValue:null},option:{fixToggle:true}},
        DefaultShadow,
        DefaultOpacity,
        DefaultRotate,
        // DefaultFilter
      ]
      editor['animation'] = [
        ...DefaultAnimation,
        DefaultMorphing,
      ]
  }
  return editor[option]??[]
}


export const ImageV1 = (preset,option) =>{
  
  const editor = {}

  switch (preset) {  
    default:
      editor['edit'] = [
        DefaultPosition,
        DefaultSize,
        {form:ImageEditForm,keyName:{mediaType : {'key' : 'mediaType', 'category':'value'},mediaSource: {'key' : 'mediaSource', 'category':'value'}},title:{editTitle:null,textValue:null},option:{fixToggle:false},option:{fixToggle:true}},
        DefaultShadow,
        DefaultRadius,
        DefaultOpacity,
        DefaultRotate,
        DefaultBrightness,
        DefaultFilter,

      ]
      editor['animation'] = [
        ...DefaultAnimation,
        DefaultMorphing,
      ]
  }
  return editor[option]??[]
}

export const VideoV1 = (preset,option) =>{
  
  const editor = {}

  switch (preset) {  
    default:
      editor['edit'] = [
        DefaultPosition,
        DefaultSize,
        {form:VideoEditForm,keyName:{mediaType : {'key' : 'mediaType', 'category':'value'},mediaSource: {'key' : 'mediaSource', 'category':'value'},volume: {'key' : 'volume', 'category':'value'},startTime : {'key' : 'startTime', 'category':'value'},autoplay : {'key' : 'autoplay', 'category':'meta'},copyright : {'key' : 'copyright', 'category':'value'},loop : {'key' : 'loop', 'category':'value'},},title:{editTitle:null,textValue:null},option:{fixToggle:false},option:{fixToggle:true}},
        DefaultShadow,
        DefaultRadius,
        DefaultOpacity,
        DefaultRotate,
        DefaultBrightness,
        DefaultFilter,

      ]
      editor['animation'] = [
        ...DefaultAnimation,
        DefaultMorphing,
      ]
  }
  return editor[option]??[]
}

export const CameraV1 = (preset,option) =>{
  
  const editor = {}

  switch (preset) {  
    default:
      editor['edit'] = [
        DefaultPosition,
        DefaultSize,
        {form:CameraEditForm,keyName:{mediaType : {'key' : 'mediaType', 'category':'value'},mediaSource: {'key' : 'mediaSource', 'category':'value'},startTime : {'key' : 'startTime', 'category':'value'},autoplay : {'key' : 'autoplay', 'category':'meta'},copyright : {'key' : 'copyright', 'category':'value'},loop : {'key' : 'loop', 'category':'value'},},title:{editTitle:null,textValue:null},option:{fixToggle:false},option:{fixToggle:true}},
        DefaultShadow,
        DefaultRadius,
        DefaultOpacity,
        DefaultRotate,
        DefaultBrightness,
        DefaultFilter,

      ]
      editor['animation'] = [
        ...DefaultAnimation,
        DefaultMorphing,
      ]
  }
  return editor[option]??[]
}

export const ChatV1 = (preset,option) =>{
  
  const editor = {}

  switch (preset) {  
    default:
      editor['edit'] = [
        DefaultPosition,
        DefaultSize,
        {form:ChatV1Edit,keyName:{service : {'key' : 'service', 'category':'value'}, },title:{editTitle:null,textValue:null},option:{fixToggle:true}},
        DefaultPreset,
        DefaultShadow,
        DefaultRadius,
        DefaultOpacity,
        DefaultRotate,
        DefaultBrightness,
        DefaultFilter,

      ]
      editor['animation'] = [
        ...DefaultAnimation,
        DefaultMorphing,
      ]
  }
  return editor[option]??[]
}


export const TimerV1 = (preset,option) =>{
  
  const editor = {}

  switch (preset) {  
    default:
      editor['edit'] = [
        DefaultPosition,
        DefaultSize,
        {form:TimerEditForm,keyName:{fontFamily: {'key' : 'fontFamily', 'category':'style'},color: {'key' : 'color', 'category':'style'},timerType: {'key' : 'timerType', 'category':'value'},time: {'key' : 'time', 'category':'value'},endTime: {'key' : 'endTime', 'category':'value'},},title:{editTitle:null,textValue:null},option:{fixToggle:false},option:{fixToggle:true}},
        DefaultPreset,
        DefaultShadow,
        DefaultRadius,
        DefaultOpacity,
        DefaultRotate,
        DefaultBrightness,
        DefaultFilter,

      ]
      editor['animation'] = [
        ...DefaultAnimation,
        DefaultMorphing,
      ]
  }
  return editor[option]??[]
}

export const StopWatchV1 = (preset,option) =>{
  
  const editor = {}

  switch (preset) {  
    default:
      editor['edit'] = [
        DefaultPosition,
        DefaultSize,
        {form:StopWatchEditForm,keyName:{fontFamily: {'key' : 'fontFamily', 'category':'style'},color: {'key' : 'color', 'category':'style'},timerType: {'key' : 'timerType', 'category':'value'},time: {'key' : 'time', 'category':'value'},endTime: {'key' : 'endTime', 'category':'value'},},title:{editTitle:null,textValue:null},option:{fixToggle:false},option:{fixToggle:true}},
        DefaultPreset,
        DefaultShadow,
        DefaultRadius,
        DefaultOpacity,
        DefaultRotate,
        DefaultBrightness,
        DefaultFilter,

      ]
      editor['animation'] = [
        ...DefaultAnimation,
        DefaultMorphing,
      ]
  }
  return editor[option]??[]
}

export const ShapeV1 = (preset,option) =>{
  
  const editor = {}

  switch (preset) {  
    default:
      editor['edit'] = [
        DefaultPosition,
        DefaultSize,
        DefaultColor,
        // {form:ImageEditForm,keyName:{mediaType : {'key' : 'mediaType', 'category':'value'},mediaSource: {'key' : 'mediaSource', 'category':'value'}},title:{editTitle:null,textValue:null},option:{fixToggle:false},option:{fixToggle:true}},
        // DefaultPreset,
        
        // {form:TextureEditForm,keyName:{texture:{'key' : 'texture', 'category':'style'}},title:{editTitle:null},option:{openForm:false,closeEdit:true}},
        DefaultShadow,
        DefaultRadius,
        DefaultOpacity,
        DefaultRotate,
        DefaultBrightness,
        DefaultFilter,
      ]
       editor['animation'] = [
        ...DefaultAnimation,
        DefaultMorphing,
      ]
  }
  return editor[option]??[]
}


export const ParticlesV1 = (preset,option) =>{
  
  const editor = {}

  switch (preset) {  
    default:
      editor['edit'] = [
        DefaultPosition,
        DefaultSize,
        DefaultColor,
        {form:AssetEditForm,keyName:{asset:  {'key' : 'mediaAsset', 'category':'value'},},title:{editTitle:null},option:{openForm:true}},
        // {form:ImageEditForm,keyName:{mediaType : {'key' : 'mediaType', 'category':'value'},mediaSource: {'key' : 'mediaSource', 'category':'value'}},title:{editTitle:null,textValue:null},option:{fixToggle:false},option:{fixToggle:true}},
        // DefaultPreset,
        
        // {form:TextureEditForm,keyName:{texture:{'key' : 'texture', 'category':'style'}},title:{editTitle:null},option:{openForm:false,closeEdit:true}},
        DefaultShadow,
        DefaultRadius,
        DefaultOpacity,
        DefaultRotate,
        DefaultBrightness,
        DefaultFilter,
      ]
       editor['animation'] = [
        ...DefaultAnimation,
        DefaultMorphing,
      ]
  }
  return editor[option]??[]
}


export const BackgroundV1 = (preset,option) =>{
  
  const editor = {}

  switch (preset) {  
    default:
      editor['edit'] = [
        DefaultPosition,
        DefaultSize,
        {form:BG_GradientEditForm,keyName:{gradientColor:{'key' : 'gradientColor', 'category':'value'} },title:{editTitle:null,textValue:null},option:{fixToggle:true}},
        DefaultPreset,
        DefaultShadow,
        DefaultOpacity, 
        DefaultRotate,  
        // DefaultFilter
      ]
      editor['animation'] = [
        ...DefaultAnimation,
        DefaultMorphing,
      ]
  }
  return editor[option]??[]
}

export const ScoreboardV1 = (preset,option) =>{
  
  const editor = {}

  switch (preset) {  
    default:
      editor['edit'] = [
        DefaultPosition,
        DefaultSize,
        {form:ScoreboardEditForm,keyName:{
          infoFont : {'key' : 'infoFont', 'category':'style'},
          infoSize: {'key' : 'infoSize', 'category':'style'},
          teamFont : {'key' : 'teamFont', 'category':'style'},
          teamSize : {'key' : 'teamSize', 'category':'style'},
          teamColor : {'key' : 'teamColor', 'category':'style'},
          infoColor : {'key' : 'infoColor', 'category':'style'},
          reverse : {'key' : 'reverse', 'category':'value'},
          matchInfo : {'key' : 'matchInfo', 'category':'value'},
        },title:{editTitle:null,textValue:null},option:{fixToggle:false},option:{fixToggle:true}},
        {form:TeamEditForm,keyName:{
          teamData :  {'key' : 'teamData', 'category':'value'},

        },title:{editTitle:null,textValue:null},option:{fixToggle:false},option:{fixToggle:true}},
        DefaultPreset,
        DefaultShadow,
        DefaultOpacity,
        DefaultRotate,
        DefaultBrightness,
        DefaultFilter,
      ]
      editor['animation'] = [
        ...DefaultAnimation,
        DefaultMorphing,
      ]
  }
  return editor[option]??[]
}

export const MatchInfoV1 = (preset,option) =>{
  
  const editor = {}

  switch (preset) {  
    default:
      editor['edit'] = [
        DefaultPosition,
        DefaultSize,
        {form:ScoreboardEditForm,keyName:{
          infoFont : {'key' : 'infoFont', 'category':'style'},
          infoSize: {'key' : 'infoSize', 'category':'style'},
          teamFont : {'key' : 'teamFont', 'category':'style'},
          teamSize : {'key' : 'teamSize', 'category':'style'},
          teamColor : {'key' : 'teamColor', 'category':'style'},
          infoColor : {'key' : 'infoColor', 'category':'style'},
          reverse : {'key' : 'reverse', 'category':'value'},
          matchInfo : {'key' : 'matchInfo', 'category':'value'},
        },title:{editTitle:null,textValue:null},option:{fixToggle:false},option:{fixToggle:true}},
        {form:TeamEditForm,keyName:{
          teamData :  {'key' : 'teamData', 'category':'value'},

        },title:{editTitle:null,textValue:null},option:{fixToggle:false},option:{fixToggle:true}},
        DefaultPreset,
        DefaultShadow,
        DefaultOpacity,
        DefaultRotate,
        DefaultBrightness,
        DefaultFilter,
      ]
      editor['animation'] = [
        ...DefaultAnimation,
        DefaultMorphing,
      ]
  }
  return editor[option]??[]
}


export const BanPickV1 = (preset,option) =>{
  
  const editor = {}
  
  switch (preset) {  
    default:
      editor['edit'] = [
        DefaultPosition,
        DefaultSize,
        {form:AssetEditForm,keyName:{asset:  {'key' : 'banPickAsset', 'category':'value'},},title:{editTitle:null},option:{openForm:true}},
        {form:BanPickEditForm,keyName:{
          teamData :  {'key' : 'teamData', 'category':'value'},
          banPickRule : {'key' : 'banPickRule', 'category':'value'},
          asset:  {'key' : 'banPickAsset', 'category':'value'},
        },title:{editTitle:null,textValue:null},option:{fixToggle:false},option:{fixToggle:true}},

        {form:TeamEditForm,keyName:{
          teamData :  {'key' : 'teamData', 'category':'value'},

        },title:{editTitle:null,textValue:null},option:{fixToggle:false},option:{fixToggle:true}},
        
        {form:BanPickRuleEditForm,keyName:{
          infoFont : {'key' : 'infoFont', 'category':'style'},
          infoSize: {'key' : 'infoSize', 'category':'style'},
          teamFont : {'key' : 'teamFont', 'category':'style'},
          teamSize : {'key' : 'teamSize', 'category':'style'},
          teamColor : {'key' : 'teamColor', 'category':'style'},
          reverse : {'key' : 'reverse', 'category':'value'},
          banPickRule : {'key' : 'banPickRule', 'category':'value'},
          teamData :  {'key' : 'teamData', 'category':'value'},
        },title:{editTitle:null,textValue:null},option:{fixToggle:false},option:{openForm:false,closeEdit:true}},
        DefaultPreset,
        DefaultShadow,
        DefaultOpacity,
        DefaultRotate,
        DefaultBrightness,
        DefaultFilter,
      ]

      editor['animation'] = [
        ...DefaultAnimation,
        DefaultMorphing,
      ]
  }
  return editor[option]??[]
}


/**
 * 위젯 에디터 | 메모 Notice | V1
 * @param {*} preset 
 * @returns 
 */

export const NoticeV1 = (preset,option) =>{
  
  const editor = {}

  console.log('preset',preset)
  switch (preset) {  

    case 'Postit':
      editor['edit'] = [
        DefaultPosition,
        DefaultSize,
        {form:NoticeEditForm,keyName:{text : {'key' : 'text', 'category':'value'}, fontSize: {'key' : 'fontSize', 'category':'style'}, fontFamily: {'key' : 'fontFamily', 'category':'style'}, textAlign: {'key' : 'textAlign', 'category':'style'}, fontColor: {'key' : 'color', 'category':'style'}, outlineColor : {'key' : 'outlineColor', 'category':'style'}, textOutline : {'key' : 'textOutline', 'category':'style'} },title:{editTitle:null,textValue:null},option:{fixToggle:true}},
        {form:NoticeStyleEditForm,keyName:{ direction:{'key' : 'direction', 'category':'style'}, fill: {'key' : 'fill', 'category':'style'}, },title:{editTitle:null,textValue:null},option:{fixToggle:true}},
        
        DefaultPreset,
        DefaultShadow,
        DefaultOpacity,
        DefaultRotate,
        // DefaultFilter
      ]

      editor['animation'] = [
        ...DefaultAnimation,
        DefaultMorphing,
      ]

      break;
    case 'Modern':
      editor['edit'] = [
        DefaultPosition,
        DefaultSize,
        {form:NoticeEditForm,keyName:{text : {'key' : 'text', 'category':'value'}, fontSize: {'key' : 'fontSize', 'category':'style'}, fontFamily: {'key' : 'fontFamily', 'category':'style'}, textAlign: {'key' : 'textAlign', 'category':'style'}, fontColor: {'key' : 'color', 'category':'style'}, outlineColor : {'key' : 'outlineColor', 'category':'style'}, textOutline : {'key' : 'textOutline', 'category':'style'} },title:{editTitle:null,textValue:null},option:{fixToggle:true}},
        {form:NoticeStyleEditForm,keyName:{  fill: {'key' : 'fill', 'category':'style'}, },title:{editTitle:null,textValue:null},option:{fixToggle:true}},
        
        DefaultPreset,
        DefaultShadow,
        DefaultOpacity,
        DefaultRotate,
        // DefaultFilter
      ]

      editor['animation'] = [
        ...DefaultAnimation,
        DefaultMorphing,
      ]
      break;
    case 'Apps':
      editor['edit'] = [
        DefaultPosition,
        DefaultSize,
        {form:NoticeEditForm,keyName:{text : {'key' : 'text', 'category':'value'}, fontSize: {'key' : 'fontSize', 'category':'style'}, fontFamily: {'key' : 'fontFamily', 'category':'style'}, textAlign: {'key' : 'textAlign', 'category':'style'}, fontColor: {'key' : 'color', 'category':'style'}, outlineColor : {'key' : 'outlineColor', 'category':'style'}, textOutline : {'key' : 'textOutline', 'category':'style'} },title:{editTitle:null,textValue:null},option:{fixToggle:true}},
        // {form:NoticeStyleEditForm,keyName:{  fill: {'key' : 'fill', 'category':'style'}, },title:{editTitle:null,textValue:null},option:{fixToggle:true}},
        
        DefaultPreset,
        DefaultShadow,
        DefaultOpacity,
        DefaultRotate,
        // DefaultFilter
      ]

      editor['animation'] = [
        ...DefaultAnimation,
        DefaultMorphing,
      ]
      break;
    default:
      console.log('preset??',preset)
      editor['edit'] = [
        DefaultPosition,
        DefaultSize,
        {form:NoticeEditForm,keyName:{text : {'key' : 'text', 'category':'value'}, fontSize: {'key' : 'fontSize', 'category':'style'}, fontFamily: {'key' : 'fontFamily', 'category':'style'}, textAlign: {'key' : 'textAlign', 'category':'style'}, fontColor: {'key' : 'color', 'category':'style'}, outlineColor : {'key' : 'outlineColor', 'category':'style'}, textOutline : {'key' : 'textOutline', 'category':'style'} },title:{editTitle:null,textValue:null},option:{fixToggle:true}},
        // {form:NoticeStyleEditForm,keyName:{ fill: {'key' : 'fill', 'category':'style'}, },title:{editTitle:null,textValue:null},option:{fixToggle:true}},
        
        DefaultPreset,
        DefaultShadow,
        DefaultOpacity,
        DefaultRotate,
        // DefaultFilter
      ]

      editor['animation'] = [
        ...DefaultAnimation,
        DefaultMorphing,
      ]
  }

  return editor[option]??[]
}


export const ScrollCaptionV1 = (preset,option) =>{
  
  const editor = {}

  switch (preset) {  
    default:
      editor['edit'] = [
        DefaultPosition,
        DefaultSize,
        {form:ScrollCaptionEditForm,keyName:{text : {'key' : 'text', 'category':'value'}, fontSize: {'key' : 'fontSize', 'category':'style'}, fontFamily: {'key' : 'fontFamily', 'category':'style'}, textAlign: {'key' : 'textAlign', 'category':'style'}, useFill: {'key' : 'useFill', 'category':'style'}, fontColor: {'key' : 'color', 'category':'style'}, fillOpacity : {'key' : 'fillOpacity', 'category':'style'},  },title:{editTitle:null,textValue:null},option:{fixToggle:true}},
        DefaultShadow,
        DefaultOpacity,
        DefaultRotate,   
        // DefaultFilter
      ]
      editor['animation'] = [
        ...DefaultAnimation,
        DefaultMorphing,
      ]
  }
  return editor[option]??[]
}


