////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, {useState, useEffect, useRef } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
import { Route, Link } from 'react-router-dom';
import { useHistory } from "react-router";
// 라이브러리 

// 설정 파일
import * as config from 'config/OverlapConfig'

// [컴포넌트] 위젯 
// import MiniView from "components/viewer/MiniView"
// [리덕스]스토어 연결
// import store from "store";

// SASS&CSS 연결
// import 'sass/login.scss'

// 이미지 연결
// import logoW from 'assets/logoW.svg';


// 서비스 연결
import * as DeCoder from 'service/engine/DeCoder' ; 
import { transform } from 'lodash';
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////

/** [위젯선택] 
 * 프리뷰용도로 제작된 디스플레이 입니다.
 * @param {*} page 오버랩의 페이지 객체 (1개)
 * @param {number|null} previewWidth 컴포넌트의 가로 사이즈
 * @param {number|null} previewHeight 컴포넌트의 세로 사이즈
 * @param {number} referenceWidth 디스플레이의 해상도
 * @param {number} referenceHeight 디스플레이의 해상도
 * @param {*} option 디스플레이에 적용할 옵션
 * @returns 
 */


const WidgetPlaceholder =  React.memo(function WidgetPlaceholder({ x,y,w,h,r,uuid,setSelect }) {
    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////
    // state
    const [PointType, setPointType] = useState('resize');
   

    const [Width, setWidth] = useState(w);
    const [Height, setHeight] = useState(h);
    const [Xpoint, setXpoint] = useState(x);
    const [Ypoint, setYpoint] = useState(y);

    const [mouse, setMouse] = useState({x:null,y:null});

    // ref

    // const displayRef = useRef(null);

    ////////////////////////////////////////////////////////
    // Life Cycle
    ////////////////////////////////////////////////////////

      useEffect(() => {
        console.log('Component did mount or update');
        // getOverlapList() 
        // Cleanup function (optional)
        return () => {
          console.log('Component will unmount');
        };
      },[]);


      useEffect(() => {
        setWidth(w)
        setHeight(h)
        setXpoint(x)
        setYpoint(y)
        return () => {
          console.log('Component will unmount');
        };
      },[x,y,w,h]);

    ////////////////////////////////////////////////////////
    // Fuction
    ////////////////////////////////////////////////////////


    ////////////////////////////////////////////////////////
    // JSX
    ////////////////////////////////////////////////////////

    const WidgetPosition ={
      position: 'absolute',
      height: Height+'px',
      width : Width+'px',
      top : Ypoint+'px',
      left : Xpoint+'px',
      // border : '1px solid black',
      boxSizing: 'border-box',
      transform:`rotate(${r}deg)` 
      // zIndex : 0,

  }

  const selectWidget = (e) => {
    setSelect(uuid);
    console.log('selectWidget')
    e.stopPropagation();
  }

  return (
    <div className="WidgetPlaceholder" style={WidgetPosition} onClick={(e)=>selectWidget(e)}/>
  );
  });

  export default WidgetPlaceholder;
////////////////////////////////////////////////////////
// css 부분
////////////////////////////////////////////////////////
// const WidgetSelecterStyle = `
   
// `;

