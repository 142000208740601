////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, {useState, useEffect, useRef } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
import { Route, Link } from 'react-router-dom';
import { useHistory } from "react-router";
// 라이브러리 
import { useGoogleLogin } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google'// 구글 로그인
// 설정 파일
import * as config from 'config/OverlapConfig'

import { withRouter } from 'react-router-dom';

import * as apiAccount from 'service/api/apiAccount'

// 위젯 
import MiniView from "components/viewer/MiniView"
import PreviewDisplay from "components/display/PreviewDisplay"
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import 'sass/login.scss'

// 이미지 연결
import logoW from 'assets/logoW.svg';
import youtube from 'assets/main/lobby/social/youtube.png';
import twitch from 'assets/main/lobby/social/twitch.png';
import afreeca from 'assets/main/lobby/social/afreeca.png';
import facebook from 'assets/main/lobby/social/facebook.png';
import naver from 'assets/main/lobby/social/naver.png';
import { get } from 'lodash';

// 서비스 연결
import * as page from 'service/api/page';

////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////

function OverlapInfo(props) {
    // const todos = useSelector(state => state.todos)
    // const dispatch = useDispatch()
    // const addTodo = text => dispatch(addTodo(text))
    const push = () => {

    } 
    const history = useHistory();
    let [getPreview, setPreview] = useState(null);
    ////////////////////////////////////////////////////////
    // Life Cycle
    ////////////////////////////////////////////////////////

    useEffect(() => {
        getPagePreview() 
        // Cleanup function (optional)
        return () => {
          console.log('Component will unmount');
        };
      },[]);

    async function getPagePreview(){
        const NewPreview = [];
        console.log('doen',props.data.uuid,props.data)
        if(props.data?.page_order?.[0]){
            const overLapPreview = await page.getPageForUUID(props.data.page_order[0]);
            NewPreview.push(overLapPreview[0])
            setPreview(NewPreview)

        }
    }


    function setSelect(){
      props.setSelect(props.data.id,props.data.uuid,props.data);
    }

    console.log('getPreview : ',props.data.uuid,getPreview)
    return (
        <div className='overlapFile'>
              <style jsx>{`${SpaceModuleStyle}${ContainerStyle}`}</style>
              <div className = {"overlapInfo "+props.data.uuid} onClick={()=>setSelect()}>

                <div className='upperInfo'>
                  <div className = "overlapState">
                  {/* <svg className="broadcastScreenLive" width="30px" height="30px" xmlns="http://www.w3.org/2000/svg" version="1.1">
                    <defs>
                        <filter id="shadow" x="-20%" y="-20%" width="140%" height="140%">
                            <feDropShadow dx="1" dy="1" stdDeviation="1"/>
                        </filter>
                    </defs>
                    <circle cx="10" cy="10" r="8" fill="red" stroke="#fff" strokeWidth="3" filter="url(#shadow)" />
                </svg> */}
                  </div> 
                  {getPreview == null? 
                    <div className = "preView readyPreView" >
                      <img className='preViewIcon' style={{aspectRatio : `${props.data.resolution_width} / ${props.data?.resolution_height}`,}}  src={logoW}/>
                    </div> 
                    :
                    <div className = "preView">
                      <PreviewDisplay pageList={getPreview} referenceWidth={props.data.resolution_width??0} referenceHeight={props.data?.resolution_height??0}/>  
                      {/* <MiniView zoom={0.1} data={getPreview?.[0]?.page_widget?getPreview?.[0]?.page_widget:[]} />   */}
                    </div> 
                }
                </div>
                <div className='lowerInfo'>
                  <div className = "info">
                      <div className = "title">
                      {props.data?.title}
                      </div>
                      {/* <div className = "summary">
                      오버랩의 타이틀 오버랩의 타이틀 오버랩의 타이틀 오버랩의 타이틀 오버랩의 타이틀 오버랩의 타이틀 오버랩의 타이틀 오버랩의 타이틀
                      </div> */}
                  </div>
                </div>
              </div>
            </div>
    );
  }





  export default OverlapInfo;

  const SpaceModuleStyle = `
  .ViewListContainer {

  }
  
  `;
  
  const ContainerStyle = `
  @keyframes start {
    0% {
      opacity: 0;

    }
    100% {
      opacity: 1;

    }
  }

  .overlapFile {
    animation: start 0.6s forwards;
      display: flex;
      justify-content: center;
      width: 100%;
  }
  .overlapFile .overlapInfo{
      justify-self: center;
      box-sizing: content-box;
      width: 192px;
      height: 150px;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      transition: all 0.3s;
  }
  .overlapFile .upperInfo{
      position: relative;
      width: 192px;
      height: 108px;
  }
  .overlapFile .viewState{
      position: absolute;
      display: flex;
      justify-content: end;
      width: 192px;
      padding: 5px 5px;
      height: 40px;
      z-index: 1;
  }
  .overlapFile .preView{
      position: absolute;
      width: 192px;
      height: 108px;
      overflow: hidden;
      border-radius: 5px ;
      transition: all 0.5s;
  }
  .overlapFile .readyPreView{
      background-color: rgb(28, 28, 28);
      display: flex;
      align-items: center;
      justify-content: center;
      margin: auto;
  }
  .overlapFile .MiniViewloding{
      box-sizing: border-box;
      border: 3px solid white;       
      background-image: url('../assets/logoW.svg');
      background-repeat: no-repeat;
      background-size: 80% 80%;
      background-position: center center;    
  }
  .overlapFile .lowerInfo{
  
  }
  .overlapFile .info{
    display: flex;
    justify-content: center;
  }
  .overlapFile .title{
      overflow: hidden;
      height: 20px;
      font-size: 15px;
      font-family: '프리텐다드Regular';
      line-height: 15px;
      margin: 10px 0px;
  }
  .overlapFile .readyPreView .preViewIcon{
    width: 60%;
    height: 60%;
}
  `;